import { OfferContentManager } from '../OfferContentManager';

export const override = <T = any>(correlationId: string, contentManager: OfferContentManager, section: T): T => {
  return contentManager.getCampaignOverride<T>(correlationId, 'homepagePrimaryBanner', section);
};

export default {
  // always include base-path in paths
  path: ['/go'],
  contentType: ['sectionPrimaryBanner'],
  override
};
