import fetch from 'isomorphic-unfetch';
import { qs } from 'url-parse';

import { HEADERS, NBN_SESSION_KEYS } from '@belong/constants';
import {
  IAddress,
  IAddressV2,
  IPartialAddress,
  IServiceQualificationResponse,
  NBN_TECH_TYPE,
  SERVICE_QUALIFICATION_RESULTS,
  SERVICEABILITY_STATUS
} from '@belong/types';
import { getConfig } from '@belong/configs/next/config';

import { NBNServiceClass, API_ENDPOINTS } from './types';

import { isBlueHost } from '../requestOrigin';
import { getCorrelationId } from '../session';

export { NBNServiceClass, API_ENDPOINTS } from './types';

const newOrigin = (): string => {
  const { host: hostGreen, hostBlue } = getConfig()?.publicRuntimeConfig?.gateway || {};

  return isBlueHost() ? hostBlue : hostGreen;
};

/**
 * Convert a numeric service class into a `NBNServiceClass` type. If given a
 * single argument this will return the corresponding service class. If given
 * two arguments it will return an array with all the service classes between
 * `startIndex` and `endIndex` (inclusive).
 *
 * @example
 *
 *   getServiceClass(0)
 *   // "NBN_SERVICE_CLASS_ZERO"
 *
 *   getServiceClass(0, 2)
 *   // ["NBN_SERVICE_CLASS_ZERO", "NBN_SERVICE_CLASS_ONE", "NBN_SERVICE_CLASS_TWO"]
 */
export function getServiceClass(startIndex: number): NBNServiceClass;
export function getServiceClass(startIndex: number, endIndex?: number): NBNServiceClass[];
export function getServiceClass(startIndex: number, endIndex?: number): NBNServiceClass | NBNServiceClass[] {
  const keys = Object.keys(NBNServiceClass);
  if (startIndex < 0 || startIndex >= keys.length) {
    throw new RangeError('Service class must be between 0 and 34');
  }
  if (typeof endIndex !== 'number') {
    return NBNServiceClass[keys[startIndex]];
  }
  if (endIndex < 0 || endIndex >= keys.length) {
    throw new RangeError('Service class must be between 0 and 34');
  }
  return keys.slice(startIndex, endIndex + 1).map(key => NBNServiceClass[key]);
}

/**
 * Returns the broadband technology type for a serviceability payload.
 */
export const getTechType = (serviceabilityDetails: IServiceQualificationResponse): NBN_TECH_TYPE | undefined => {
  const serviceQualification = serviceabilityDetails.data
    ? serviceabilityDetails.data[0].serviceQualification
    : undefined;
  if (serviceQualification) {
    return serviceQualification.nbnTechType;
  }
  return undefined;
};

const standardHeaders = {
  [HEADERS.ACCEPT]: 'application/json',
  [HEADERS.CONTENT_TYPE]: 'application/json'
};

const toJson = (r: Response): Promise<any> => r.json();

export interface IServiceQuery {
  getPartialAddresses: (searchQuery: string) => Promise<IPartialAddress[]>;
  getAddressLines: (moniker: string) => Promise<IAddressV2>;
  getStrategicAddressEntity: (payload: IAddress) => Promise<IServiceQualificationResponse>;
  getStrategicServiceability: (addressId: string) => Promise<IServiceQualificationResponse>;
}

/**
 * A client for the Service Qualification APIs
 *
 * @see https://belongranda.atlassian.net/wiki/spaces/BEL/pages/1691386148/Strategic+SQ+End+to+End+Implementation
 */
function getEndpoint(endpoint: API_ENDPOINTS, base): string {
  return new URL(endpoint, base).toString();
}

export async function getPartialAddresses(searchQuery: string): Promise<IPartialAddress[]> {
  const queryParams = { searchString: searchQuery, filterPO: 'true' };
  const queryString = qs.stringify(queryParams).replace(/^(.)/, '?$1');
  const endpoint = getEndpoint(API_ENDPOINTS.PARTIAL_ADDRESS_SEARCH, newOrigin());

  const response = await fetch(`${endpoint}${queryString}`, {
    method: 'GET',
    headers: {
      [HEADERS.CORRELATION_ID]: getCorrelationId(),
      ...standardHeaders
    }
  });

  return toJson(response);
}

export async function getAddressLines(moniker: string): Promise<IAddressV2> {
  const response = await fetch(getEndpoint(API_ENDPOINTS.GET_ADDRESS_V2_FRAGMENTS, newOrigin()), {
    method: 'POST',
    headers: {
      [HEADERS.CORRELATION_ID]: getCorrelationId(),
      ...standardHeaders
    },
    body: JSON.stringify({
      moniker,
      filterPO: true
    })
  });

  return toJson(response);
}

/**
 * @description Make a POST request to strategic SQ
 * for address entity. There are two positive scenarios :
 * 1. One address is returned and serviceable
 * 2. Multiple addresses are returned and we need to make a second
 * GET request via getStrategicServiceability()
 *
 * If it's scenario1, getStrategicServiceability() is skipped.
 *
 * @returns Promise of service qualification result
 */
export async function getStrategicAddressEntity(payload: IAddress): Promise<IServiceQualificationResponse> {
  const url = new URL(getEndpoint(API_ENDPOINTS.ADDRESS_ENTITY, newOrigin()));
  return fetch(url.toString(), {
    method: 'POST',
    headers: {
      [HEADERS.CORRELATION_ID]: getCorrelationId(),
      ...standardHeaders
    },
    body: JSON.stringify(payload, (_, value) => (value !== '' ? value : undefined))
  }).then(toJson);
}

/**
 *
 * @param addressId string
 * @description Make a GET request to strategic SQ with addressId as the query param
 * @returns Promise of service qualification result
 */
export async function getStrategicServiceability(addressId: string): Promise<IServiceQualificationResponse> {
  const url = new URL(getEndpoint(API_ENDPOINTS.ADDRESS_ENTITY, newOrigin()));
  return fetch(`${url}/${addressId}`, {
    method: 'GET',
    headers: {
      [HEADERS.CORRELATION_ID]: getCorrelationId(),
      ...standardHeaders
    }
  }).then(toJson);
}

/**
 * return serviceability status returned by adsq endpoint
 */
export const serviceabilityOutcome = ({ result, data }: IServiceQualificationResponse): SERVICEABILITY_STATUS => {
  //  Confirmed by BE that NO_LOCATION result means the address is not serviceable
  if (result === SERVICE_QUALIFICATION_RESULTS.NO_LOCATION) {
    return SERVICEABILITY_STATUS.SERVICE_NOT_AVAILABLE;
  }

  if (result !== SERVICE_QUALIFICATION_RESULTS.SQ) {
    return SERVICEABILITY_STATUS.SOMETHING_WENT_WRONG;
  }
  const statusCode = data[0].serviceQualification
    ? data[0].serviceQualification.status
    : SERVICEABILITY_STATUS.SOMETHING_WENT_WRONG;
  return SERVICEABILITY_STATUS[statusCode];
};

export const clearServiceQueryData = ({ storage }: { storage: Storage }): void => {
  [
    // Service qualification (SQ)
    NBN_SESSION_KEYS.SQ_DONE,
    NBN_SESSION_KEYS.SQ_MODEL,
    // Auto referral SQ
    'sqFlow',
    // Product choice
    NBN_SESSION_KEYS.PRODUCTS,
    // Nbn Journey nbn store
    NBN_SESSION_KEYS.STORE,
    // Nbn Journey nbn order
    NBN_SESSION_KEYS.NBN_ORDER,
    'nbnCustomer',
    // implicit promotions
    'nbnPromo'
  ].forEach(key => {
    storage.removeItem(key);
  });
};
