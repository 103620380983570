import { BUTTON_ID_PREFIX, BUTTON_TEXT } from './styles';

export const getButtonElementId = (buttonId: string): string => `${BUTTON_ID_PREFIX}${buttonId}`;
export const LIVE_AGENT_SESSION_ID = 'liveAgentSessionId';
export const makeButton = (buttonId: string): HTMLElement => {
  const theButton = document.createElement('button');

  theButton.id = getButtonElementId(buttonId);
  theButton.setAttribute('title', BUTTON_TEXT);
  theButton.setAttribute('data-state', 'unstarted');
  theButton.innerHTML = `
    
  `;

  document.body.appendChild(theButton);
  return theButton;
};

/**
 * We need an accurate way to determine when salesforce.js has loaded
 * and initialised -- and the <script onLoad={...} /> doesn't cut it
 * because the actual data objects haven't been initialised by then.
 *
 * Unfortunately, in the absence of any real "liveagent.onReady()"
 * hook to listen to, this is the best we can do. Big dreams, medium results.
 */
export const isLiveAgentReady = (): boolean => {
  return Boolean((window as any).liveagent);
};

/** when liveagent is loaded in window, we extract the session id and add to the session storage.
 * Key: `liveAgentSessionId`
 * Value: window.liveagent.getSid() */
export const setLiveAgentSessionId = (liveAgentSessionId: string): void => {
  (window as any).sessionStorage.setItem(LIVE_AGENT_SESSION_ID, liveAgentSessionId);
};

export const getSessionId = (): string | undefined => {
  if (isLiveAgentReady()) {
    return (window as any).liveagent.getSid();
  }
  return undefined;
};
