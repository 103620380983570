import {
  IAppliedPromotion,
  ICatalogOffer,
  ICatalogOfferReward,
  IMoleculeAlert,
  IElementOfferAlert,
  ICatalogPromotion,
  IExpiredPromotion,
  IPlanPromotion
} from '@belong/types';
import {
  IAppliedOfferReward,
  IOffer,
  IOfferReward,
  OFFER_REWARD_ATTRIBUTE_NAME,
  OFFER_STATUS
} from '@belong/types/api/offers';
import { IOfferDetails } from '@belong/types/api/orders';

type PromotionType = IPlanPromotion | ICatalogPromotion | IAppliedPromotion;
type OfferType = IOffer | ICatalogOffer | IOfferDetails;
type RewardType = IOfferReward | ICatalogOfferReward | IAppliedOfferReward;
export type AnyOfferOrReward = PromotionType | OfferType | RewardType;

export const shouldLoadOrderOfferDetails = (offer: IOfferDetails): boolean =>
  [OFFER_STATUS.ACTIVE, OFFER_STATUS.NEW].includes(offer.status);

export const isOffer = (item?: AnyOfferOrReward): item is IOffer | ICatalogOffer => {
  if (!item) {
    return false;
  }
  return 'rewards' in item;
};

export const isOfferReward = (item?: AnyOfferOrReward): item is IAppliedOfferReward | ICatalogOfferReward => {
  // Applied Offer Rewards MUST have an `offerId`
  // OfferRewards & CatalogOfferRewards MUST have `productAttributes`
  // Anything else is NOT an offer reward
  if (!item) {
    return false;
  }

  // IAppliedOfferReward
  if ('offerId' in item) {
    return true;
  }

  // IOfferReward || ICatalogOfferReward
  return 'productAttributes' in item;
};

export const isAppliedPromotion = (item?: AnyOfferOrReward): item is IAppliedPromotion => {
  if (!item) {
    return false;
  }
  return 'remainingMonths' in item && 'code' in item;
};

export const isPlanPromotion = (
  item?: IAppliedOfferReward | IAppliedPromotion | ICatalogOfferReward | IPlanPromotion | IOfferReward
): item is IPlanPromotion => {
  if (!item) {
    return false;
  }
  return 'productTerm' in item;
};

export const isAppliedOfferReward = (item?: AnyOfferOrReward): item is IAppliedOfferReward => {
  if (!item) {
    return false;
  }
  return 'offerId' in item && !('productAttributes' in item);
};

// TRUE if reward has affected the service in the CURRENT bill cycle
export const isActiveReward = (reward: IAppliedOfferReward): boolean => {
  return (reward?.remainingMonths ?? 0) < (reward?.durationInMonths ?? 0);
};

// TRUE if reward will affect the service in the NEXT bill cycle
// (Assumes reward is either active or will start in next bill cycle)
export const isOnGoingReward = (reward: IAppliedOfferReward | IAppliedPromotion | IExpiredPromotion): boolean => {
  return (reward?.remainingMonths ?? 0) > 0;
};

export const isRecurringReward = (reward?: IAppliedOfferReward | ICatalogOfferReward): boolean => {
  const duration = isAppliedOfferReward(reward)
    ? reward.durationInMonths
    : parseInt((reward?.productAttributes[OFFER_REWARD_ATTRIBUTE_NAME.DURATION] ?? 0).toString(), 10);
  return (duration ?? 0) > 1;
};

export const isOfferAlertTemplate = (
  template?: IElementOfferAlert | IMoleculeAlert
): template is IElementOfferAlert => {
  if (!template) {
    return false;
  }
  return 'callout' in template;
};

export const isMoleculeAlertTemplate = (template?: IElementOfferAlert | IMoleculeAlert): template is IMoleculeAlert => {
  if (!template) {
    return false;
  }
  return 'message' in template;
};

export const getIdFromReward = (reward: IAppliedOfferReward | IAppliedPromotion): string => {
  if (isAppliedOfferReward(reward)) {
    return reward.offerId;
  }

  if (isAppliedPromotion(reward)) {
    return reward.id;
  }

  return '';
};

export const getDurationFromReward = (
  reward: IAppliedOfferReward | IAppliedPromotion | ICatalogOfferReward | IPlanPromotion | IOfferReward
): number => {
  if (isAppliedOfferReward(reward)) {
    return reward.durationInMonths;
  }

  if (isAppliedPromotion(reward)) {
    return reward.duration;
  }

  if (isPlanPromotion(reward)) {
    return reward.productTerm.duration.value;
  }

  return 0;
};

export const getDataFromReward = (reward: IAppliedOfferReward | IAppliedPromotion): number => {
  if (isAppliedOfferReward(reward)) {
    return reward.dataSizeInGb;
  }

  if (isAppliedPromotion(reward)) {
    return reward.amount.value;
  }

  return 0;
};
