import { IData, PRODUCT_FAMILY } from './product';
import { IOfferRewardProductAttribute, OFFER_CHANNEL_ACTION, OFFER_TYPE } from './api/offers';

export const OFFER_REWARD_UNIT = {
  data: 'gb',
  credit: '$',
  duration: 'month'
};

export enum OFFER_REWARD_TYPE {
  UNKNOWN = 'unknown',
  CREDIT = 'credit',
  DATA = 'data'
}

export enum OFFER_CHANNEL {
  SALESFORCE = 'salesforce',
  DIGITAL = 'digital',
  APP = 'app'
}

export interface IExpiredPromotion extends Partial<IAppliedPromotion> {
  code: string;
}

export interface IAppliedPromotion {
  id: string;
  code: string;
  name?: string;
  amount: IData;
  expiryDate: string;
  duration: number;
  durationUnit: string;
  remainingMonths: number;
}

export interface ICatalogPromotion {
  promotionName: string;
  promotionCode: string;
  planProductCode: string;
  description: string;
  effectUnit: string;
  effectValue: number;
  durationUnit: string;
  durationValue: number;
  recurrence: string;
  availability: string;
  isActive: boolean;
  endDate: string;
}

export interface IPlanPromotion {
  id?: string; // duplicate of promotionCode to match IOffer
  promotionCode: string;
  promotionName: string;
  description: string;
  recurrence: string;
  productTerm: IPlanPromotionProductTerm;
  amount: IPlanPromotionValue;
  isActive: boolean;
  productFamily?: PRODUCT_FAMILY;
  planProductCode?: string;
  effectType?: string;
  availability?: string;
  endDate?: string;
}

export interface IPlanPromotionValue {
  unit: string;
  value: number;
}

export interface IPlanPromotionPeriod {
  startDateTime: string;
  endDateTime: string;
}

export interface IPlanPromotionProductTerm {
  duration: IPlanPromotionValue;
  validFor: IPlanPromotionPeriod;
  description?: string;
  name?: string;
}

export interface ICatalogOffer {
  id: string;
  name: string;
  groupingId?: string;
  description: string;
  productFamily: PRODUCT_FAMILY;
  type: OFFER_TYPE;
  startDate: string;
  endDate: string;
  isActive: boolean;
  isATL: boolean;
  eligiblePlans: string[];
  eligibleChannels: OFFER_CHANNEL[];
  channelAction: OFFER_CHANNEL_ACTION;
  rewards: ICatalogOfferReward[];
}

export type ProductAttributeMap = Record<string, IOfferRewardProductAttribute['value']>;

export interface ICatalogOfferReward {
  productId: string;
  description: string;
  productAttributes: ProductAttributeMap;
}
