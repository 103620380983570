import { IOrganismServiceAdd } from '@belong/types';
import { OfferContentManager } from '../OfferContentManager';

export const override = <T = any>(correlationId: string, contentManager: OfferContentManager, section: T): T => {
  const additionalTile = contentManager.getCampaignOverride<IOrganismServiceAdd>(correlationId, 'offerSecondaryTile');

  if (additionalTile) {
    (section as any).secondaryTiles.unshift(additionalTile);
  }

  return section;
};

export default {
  // always include base-path in paths
  path: ['/new/services', '/new/services/mobile/:serviceId'],
  contentType: ['sectionServices', 'sectionManageService'],
  override
};
