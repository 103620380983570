import React from 'react';
import { NextPage } from 'next';
import Head from 'next/head';

import { logError, logger } from '@belong/logging';
import { ID } from '@belong/types';

import { ErrorPageContent } from '../../contentBlocks';

interface IErrorPage {
  statusCode?: number;
}

export const ErrorPage: NextPage<IErrorPage> = ({ statusCode }) => {
  logger.debug('ErrorPage', { statusCode });
  return (
    <>
      <Head>
        <title key="title">{`${statusCode || 'There was an'} Error`}</title>
      </Head>
      <ErrorPageContent id={ID.CONTENT} statusCode={statusCode} />
    </>
  );
};

ErrorPage.getInitialProps = ({ res, err }) => {
  const statusCode = res?.statusCode ?? err?.statusCode;
  logError(err as Error);
  return { statusCode };
};

ErrorPage.displayName = 'ErrorPage';
