import { isObject } from '@belong/utils';
import { OFFER_REWARD_FULFILMENT_TYPE } from '@belong/types/api/offers';

export const OFFER_DATE_FORMAT = {
  STANDARD: 'DD MMMM YYYY',
  SHORT: 'MMM YY',
  LONG: 'DD MMM YYYY'
};

export enum OFFER_TEMPLATE_TYPE {
  INFO = 'INFO',
  APPLIED = 'APPLIED',
  ACTIVATION = 'ACTIVATION',
  ALERT = 'ALERT',
  PLAN_CARD = 'PLAN_CARD'
}

export enum REWARD_RECURRENCE {
  ONCE_OFF = 'ONCE_OFF',
  RECURRING = 'RECURRING'
}

export const REWARD_TEMPLATE_NAMES = {
  data: {
    [REWARD_RECURRENCE.RECURRING]: {
      // Immediate variants
      [OFFER_TEMPLATE_TYPE.INFO]: 'OFFER_INFO_DATA_RECURRING',
      [OFFER_TEMPLATE_TYPE.PLAN_CARD]: 'OFFER_INFO_DATA_RECURRING_PLAN_CARD',
      [OFFER_TEMPLATE_TYPE.APPLIED]: 'APPLIED_OFFER_INFO_DATA_RECURRING',
      [OFFER_TEMPLATE_TYPE.ACTIVATION]: 'ACTIVATION_OFFER_INFO_DATA_RECURRING',
      [OFFER_TEMPLATE_TYPE.ALERT]: 'ACTIVATED_OFFER_INFO_DATA_RECURRING',

      // Bill-cycle aligned variants
      [OFFER_REWARD_FULFILMENT_TYPE.BILL_CYCLE_ALIGNED]: {
        [OFFER_TEMPLATE_TYPE.INFO]: 'OFFER_INFO_DATA_RECURRING_BILL_CYCLE_ALIGNED',
        [OFFER_TEMPLATE_TYPE.ALERT]: 'ACTIVATED_OFFER_INFO_DATA_RECURRING_BILL_CYCLE_ALIGNED',
        [OFFER_TEMPLATE_TYPE.ACTIVATION]: 'ACTIVATION_OFFER_INFO_DATA_RECURRING_BILL_CYCLE_ALIGNED',
        [OFFER_TEMPLATE_TYPE.PLAN_CARD]: 'OFFER_INFO_RECURRING_BILL_CYCLE_ALIGNED_PLAN_CARD'
      }
    },
    [REWARD_RECURRENCE.ONCE_OFF]: {
      // Immediate variants
      [OFFER_TEMPLATE_TYPE.INFO]: 'OFFER_INFO_DATA',
      [OFFER_TEMPLATE_TYPE.PLAN_CARD]: 'OFFER_INFO_DATA_PLAN_CARD',
      [OFFER_TEMPLATE_TYPE.APPLIED]: 'APPLIED_OFFER_INFO_DATA',
      [OFFER_TEMPLATE_TYPE.ACTIVATION]: 'ACTIVATION_OFFER_INFO_DATA',
      [OFFER_TEMPLATE_TYPE.ALERT]: 'ACTIVATED_OFFER_INFO_DATA',

      // Bill-cycle aligned variants
      [OFFER_REWARD_FULFILMENT_TYPE.BILL_CYCLE_ALIGNED]: {
        [OFFER_TEMPLATE_TYPE.INFO]: 'OFFER_INFO_DATA_BILL_CYCLE_ALIGNED',
        [OFFER_TEMPLATE_TYPE.ALERT]: 'ACTIVATED_OFFER_INFO_DATA_BILL_CYCLE_ALIGNED',
        [OFFER_TEMPLATE_TYPE.ACTIVATION]: 'ACTIVATION_OFFER_INFO_DATA_BILL_CYCLE_ALIGNED',
        [OFFER_TEMPLATE_TYPE.PLAN_CARD]: 'OFFER_INFO_ONCE_OFF_BILL_CYCLE_ALIGNED_PLAN_CARD'
      }
    }
  }
};

function getValues(obj: Record<string, any>): string[] {
  return Object.values(obj).flatMap(value => (isObject(value) ? getValues(value) : value));
}

export const getTemplateList = (): string[] => getValues(REWARD_TEMPLATE_NAMES);
