import structuredClone from '@ungap/structured-clone';
import { OFFER_CHANNEL_ACTION } from '@belong/types/api/offers';
import { applyDictionaryRecursively } from '@belong/utils';
import { IElementOfferAlert } from '@belong/types';
import { OfferContentManager } from '../OfferContentManager';
import { OFFER_TEMPLATE_TYPE } from '../templates/config';
import { offerToDictionary } from '../templates';

export const override = <T = any>(_, contentManager: OfferContentManager, section: T): T => {
  const plans = (section as any).plans.map(planCard => {
    const offerDetails = contentManager.getOfferForPlan(planCard.plan.productCode, OFFER_CHANNEL_ACTION.ACTIVATION);
    if (!offerDetails) {
      return planCard;
    }

    const dictionary = offerToDictionary(offerDetails);
    const template = contentManager.getRewardTemplate<IElementOfferAlert>(
      offerDetails.rewards[0],
      OFFER_TEMPLATE_TYPE.PLAN_CARD
    );

    return {
      ...planCard,
      planCallout: applyDictionaryRecursively(dictionary, template.callout)
    };
  });

  return structuredClone({ ...section, plans });
};

export default {
  // always include base-path in paths
  path: ['/go/mobile'],
  contentType: ['sectionPlanSummary'],
  override
};
