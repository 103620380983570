import * as React from 'react';
import { ReactNode, createContext, useContext } from 'react';

interface ICreateProvider<TH, TP> {
  hook: TH;
  provider: React.FC<TP>;
}

/**
 * Creates a React Context Provider that will trigger a tree re-render when the
 * provided custom hook would normally trigger a re-render.
 *
 * Place the <Provider> somewhere in your app's component tree and use the hook
 * like a built-in one.
 */
export const createProvider = <THookSignature, TProviderInterface = any>(
  theHook,
  name
): ICreateProvider<THookSignature, TProviderInterface> => {
  /**
   * Provide no default value here for two reasons:
   * 1. To enable throwing an error if the `<Provider>` is not present in the app's
   *    component tree.
   * 2. The default value is immediately thrown away when we pass the real hook
   *    value into the `<Context.Provider>`
   */
  const Context = createContext<THookSignature>(null as any);

  const provider: React.FC<TProviderInterface | any> = ({ children, ...providerProps }: { children: ReactNode }) => {
    return <Context.Provider value={theHook(providerProps)}>{children}</Context.Provider>;
  };

  /**
   * "Create" a custom hook that inherits the behaviour from the hook attached
   * to our context. This is the one which the application will ultimately use
   * and defer to `theHook` as passed in above.
   */
  const useHook = () => {
    const hookInstance = useContext(Context);

    /**
     * @see https://medium.com/@dai_shi/a-thought-on-react-context-default-value-fb3283cb5788
     */
    if (hookInstance === null) {
      throw new ReferenceError(`You probably forgot to add my <Provider> : ${name} into your app tree.`);
    }

    return hookInstance;
  };

  return {
    provider,
    hook: useHook as unknown as THookSignature
  };
};

export default createProvider;
