import * as React from 'react';
import { getConfig } from '@belong/configs/next/config';

import useAnalyticsEvents from '@belong/providers/analyticsEvents';

import { useCallback, useEffect, useState } from 'react';
import useLiveAgent from './useLiveAgent';
import { BUTTON_TEXT } from './styles';
import { getButtonElementId, setLiveAgentSessionId } from './utils';
import LiveChatButton from './TheButtonWithPortal';

interface ILiveAgentConfig {
  deploymentId: string;
  organizationId: string;
  buttonId: string;
  baseUrl: string;
}

const config = getConfig()?.publicRuntimeConfig?.liveagent as ILiveAgentConfig;

const LiveChat = ({ enabled }: { enabled?: boolean }) => {
  const analyticsEvents = useAnalyticsEvents();
  const [theButton, setTheButton] = useState<HTMLElement | undefined>();

  const onButtonShown = useCallback(() => {
    analyticsEvents.send({
      category: 'live chat',
      action: 'displayed',
      label: config.buttonId,
      component: LiveChat.displayName,
      pageUrl: window.location.href
    });
  }, [analyticsEvents]);

  const { sessionId, startChat, isAgentAvailable } = useLiveAgent({
    enabled: enabled || false,
    buttonId: config.buttonId,
    sdkBaseUrl: config.baseUrl,
    organizationId: config.organizationId,
    deploymentId: config.deploymentId,
    theButton
  });

  const onButtonClick = useCallback(() => {
    analyticsEvents.send({
      category: 'live chat',
      action: 'open',
      label: BUTTON_TEXT,
      component: LiveChat.displayName
    });

    startChat();
  }, [analyticsEvents, startChat]);

  /**
   * Sync externally-defined session id into our own storage for
   * use later in the sales flow (to attribute conversions to
   * chat sessions where appropriate)
   */
  useEffect(() => {
    if (sessionId) {
      setLiveAgentSessionId(sessionId);
    }
  }, [sessionId]);

  useEffect(() => {
    const interval = setInterval(() => {
      const buttonNode = document.getElementById(getButtonElementId(config.buttonId));
      if (buttonNode) {
        clearInterval(interval);
        setTheButton(buttonNode);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (enabled && isAgentAvailable) {
      onButtonShown();
    }
  }, [isAgentAvailable, onButtonShown, enabled]);

  let shouldForceHideButton = false;

  if (!enabled || !isAgentAvailable) {
    shouldForceHideButton = true;
  }

  return <LiveChatButton hidden={shouldForceHideButton} onClick={onButtonClick} buttonId={config.buttonId} />;
};

LiveChat.displayName = 'LiveChat';

export default LiveChat;
