import type { GetServerSidePropsContext, NextPageContext } from 'next';
import { v4 as uuidv4 } from 'uuid';

import { COOKIES } from '@belong/constants';
import { deleteCookie, getCookie, setCookie } from '@belong/cookies';
import type { IAuth } from '@belong/types';
import { USER_TYPE } from '@belong/types';
import { logger } from '@belong/logging';

/**
 * Mirror the cookie expiry from belong-passport
 */
export const AGENT_MAX_AGE = 59 * 60;

export const COOKIE_OPTIONS = {
  httpOnly: false, // so we can read the value on the client side
  maxAge: undefined,
  path: '/',
  secure: process.env.NODE_ENV === 'production'
};

/**
 * Returns the correlationId value from the belong or session cookie.
 *
 * When logged in as an agent user, and the belong cookie contains a correlationId value
 * that is different than the session cookie, it updates the belong cookie with the value
 * in session cookie.
 *
 * This is to address a limitation in how we authenticate agents using Salesforce, where
 * we are unable to pass the existing correlationId to where the belong cookie is created.
 *
 * @param context - NextJS context - pass this when server-side
 * @returns string - v4 uuid
 */
export const getCorrelationId = (context?: NextPageContext | GetServerSidePropsContext): string => {
  const belongCookie = getCookie<IAuth>(COOKIES.BELONG, context, { parse: true });
  let correlationId = getCookie<string>(COOKIES.SESSION, context);

  /**
   * On Salesforce login, the correlationId in the belong cookie is set externally by
   * belong-passport, so this will update it so it matches the session cookie.
   */
  if (belongCookie?.userType === USER_TYPE.AGENT && correlationId && correlationId !== belongCookie.correlationId) {
    const updatedValues = { ...belongCookie, correlationId };

    const updatedOptions = { ...COOKIE_OPTIONS, maxAge: AGENT_MAX_AGE };
    logger.info(`Bobo agent correlationId ${belongCookie.correlationId} has been overridden by: ${correlationId}`);

    setCookie(COOKIES.BELONG, updatedValues, updatedOptions, context);
  }

  /**
   * When logged in, remove session cookie if it still exists.
   */
  if (belongCookie?.correlationId) {
    if (correlationId) {
      deleteCookie(COOKIES.SESSION);
    }

    return belongCookie.correlationId;
  }

  /**
   * When not logged in, set session cookie if it doesn't exist.
   */
  if (!correlationId) {
    correlationId = uuidv4();
    setCookie(COOKIES.SESSION, uuidv4(), COOKIE_OPTIONS, context);
  }

  return correlationId;
};
