export interface ICancellablePromise<T> {
  promise: Promise<T>;
  cancel: () => void;
}

/**
 * @see https://stackoverflow.com/a/37492399
 */
export const makeCancelable = <T = any>(promise: Promise<T>): ICancellablePromise<T> => {
  let hasCanceled = false;

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    promise.then(val => (hasCanceled ? reject(new Error('cancelled')) : resolve(val)));
    promise.catch(error => (hasCanceled ? reject(new Error('cancelled')) : reject(error)));
  });

  return {
    promise: wrappedPromise,
    cancel(): void {
      hasCanceled = true;
    }
  };
};

/**
 * Same as `makeCancelable` however the resulting promises resolves to
 * a boolean indicating whether or not the promise was canceled.
 */
export const makeWeakCancelable = (promise: Promise<any>): ICancellablePromise<boolean> => {
  const controller = new AbortController();

  return {
    promise: new Promise(resolve => {
      controller.signal.addEventListener('abort', () => {
        resolve(true);
      });
      promise.then(() => {
        resolve(false);
      });
    }),
    cancel(): void {
      controller.abort();
    }
  };
};

export class TimeoutError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'TimeoutError';
  }
}

export const withTimeout = async <T = any>(promise: Promise<T>, timeout: number): Promise<T | TimeoutError> => {
  const timeoutError = new TimeoutError(`Timed out after ${timeout}ms`);

  if (!timeout) {
    throw timeoutError;
  }

  const timer = new Promise<TimeoutError>((_, reject) => {
    setTimeout(() => {
      reject(timeoutError);
    }, timeout);
  });

  return Promise.race([promise, timer]);
};

export const blockForever = (): Promise<void> => {
  return new Promise(() => {
    /* never resolves */
  });
};
