import type { IOfferCampaign } from '@belong/types';
import type { Entry } from 'contentful';
import {
  FSectionPrimaryBanner,
  FElementOfferAlert,
  FOrganismSecondaryTile,
  FRichText,
  FMoleculeAlert
} from '@belong/contentful/factories';
import { FCatalogOffer } from './catalogOffer.factory';

const isActive = (item: { isActive: boolean }): boolean => item.isActive;

export const FOfferCampaign = (item: Entry<any>): IOfferCampaign => {
  const {
    offers,
    homepagePrimaryBanner,
    mobilePlanPageBanner,
    offerSecondaryTile,
    orderSimMessage,
    activationPromoAlert,
    draftServiceReminder,
    registerSimAlert
  } = item.fields;

  return {
    ...item.fields,
    offers: offers.map(FCatalogOffer).filter(isActive),
    homepagePrimaryBanner: homepagePrimaryBanner && FSectionPrimaryBanner(homepagePrimaryBanner),
    mobilePlanPageBanner: mobilePlanPageBanner && FSectionPrimaryBanner(mobilePlanPageBanner),
    offerSecondaryTile: offerSecondaryTile && FOrganismSecondaryTile(offerSecondaryTile),
    orderSimMessage: orderSimMessage && FRichText(orderSimMessage),
    activationPromoAlert: activationPromoAlert && FElementOfferAlert(activationPromoAlert),
    draftServiceReminder: draftServiceReminder && FRichText(draftServiceReminder),
    registerSimAlert: registerSimAlert && FMoleculeAlert(registerSimAlert)
  };
};
