import * as React from 'react';
import Script from 'next/script';

import { logger } from '@belong/logging/logger';
import { getConfig } from '@belong/configs/next/config';

interface IPodsightsScriptProps {
  orderId: string;
  isNewUser?: boolean;
  value?: string;
}

interface IScriptProps {
  scriptName: string;
  strategy?: React.ComponentProps<typeof Script>['strategy'];
}

export const PlatformScript: React.FC = () => {
  return (
    <Script
      dangerouslySetInnerHTML={{
        __html: `document.querySelector('html').setAttribute('data-platform', navigator.platform);`
      }}
      strategy="afterInteractive"
    />
  );
};

PlatformScript.displayName = 'PlatformScript';

export const PolyfillScript: React.FC<IScriptProps> = ({ scriptName, strategy = 'afterInteractive' }) => {
  const { basePath } = getConfig().publicRuntimeConfig;
  return (
    <>
      <Script
        src={`${basePath}/static/polyfills/${scriptName}`}
        strategy={strategy}
        onError={() => {
          logger.error(
            `${scriptName} doesn't exist in this app. If you want to use it you must place it in the static/polyfills directory`
          );
        }}
      />
    </>
  );
};

PolyfillScript.displayName = 'PolyfillScript';

export const PodsightsScript: React.FC<IPodsightsScriptProps> = ({ orderId, value, isNewUser }): JSX.Element => {
  logger.info('PodsightsScript:: ', { orderId, value, isNewUser });
  return (
    <Script
      dangerouslySetInnerHTML={{
        __html: `// Installation script generated by Podsights
        (function(w, d){
          var id='pdst-capture', n='script';
          if (!d.getElementById(id)) {
            w.pdst =
              w.pdst ||
              function() {
                (w.pdst.q = w.pdst.q || []).push(arguments);
              };
            var e = d.createElement(n); e.id = id; e.async=1;
            e.src = 'https://cdn.pdst.fm/ping.min.js';
            var s = d.getElementsByTagName(n)[0];
            s.parentNode.insertBefore(e, s);
          }
          w.pdst('conf', { key: '7670209b4ff649e9be44144765064cc2' });
          w.pdst('purchase', {
            order_id: '${orderId}',  // Dynamically populate from session data
            discount_code: '',  // Dynamically populate from session data
            is_new_customer: '${isNewUser}',  // Dynamically populate from session data
            value: '${value}',  // Dynamically populate from session data
            currency: '',
          });
        })(window, document);`
      }}
      strategy="afterInteractive"
    />
  );
};

PodsightsScript.displayName = 'PodsightsScript';
