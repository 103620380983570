import React from 'react';

import useAnalyticsEvents from '@belong/providers/analyticsEvents';
import { COLOURS, ICON_SIZES } from '@belong/themes';
import {
  type ITestable,
  ICON_NAMES,
  ILinkItem,
  STATUS_INDICATOR_STATUSES,
  STATUS_INDICATOR_VARIANT
} from '@belong/types';
import { buttonClick } from '@belong/analytics';

import { StatusIndicator } from '../../feedback/StatusIndicator';
import { UnstyledList } from '../../styles/List';
import { Copy } from '../../styles/Typography';
import { Columns } from '../../ui/Columns/Columns';
import { Column } from '../../ui/Columns/Column';
import { Stack } from '../../ui/Stack/Stack';
import * as Styles from './LinkList.styles';

export const TEST_ID = {
  LIST: 'link-list',
  LINK_ITEM: 'link-item',
  LINK_ICON: 'link-item-icon',
  LINK_LABEL: 'link-item-label',
  LINK_STATUS: 'link-item-status',
  LINK_CAPTION: 'link-item-caption',
  LINK_HREF: 'link-item-href'
};

interface IProps extends ITestable {
  groupName?: string;
  items: ILinkItem[];
}

const LinkList: React.FC<IProps> = ({ 'data-testid': dataId = TEST_ID.LIST, groupName, items }) => {
  const analyticsEvents = useAnalyticsEvents();

  return (
    <UnstyledList data-testid={dataId}>
      {items.map((props, position) => {
        const { id, children, icon, ctaIcon, label, caption, status, a11yLabel, ...itemProps } = props;
        const ariaContent = itemProps['aria-label'] || a11yLabel;
        const ariaLabel = ariaContent ? { 'aria-label': ariaContent } : {};
        const iconSize = /(spot|brand)/i.test(icon?.toLowerCase() || '') ? ICON_SIZES.medium : ICON_SIZES.small;
        const testId =
          itemProps['data-testid'] || itemProps.actionType ? `action-${itemProps.actionType}` : TEST_ID.LINK_HREF;

        return (
          <Styles.ListItem id={id} key={id || label} data-testid={TEST_ID.LINK_ITEM}>
            <Styles.Link
              tabindex="-1"
              onClick={e => {
                analyticsEvents.send({
                  ...buttonClick({
                    label,
                    text: `link-list-item-${(groupName ?? 'unknown-group').replace(/\s/g, '-').toLowerCase()}-${
                      position + 1
                    }`
                  }),
                  listPosition: position + 1,
                  groupName
                });
                props.onClick?.(e);
              }}
              {...ariaLabel}
              {...itemProps}
              data-testid={testId}
            >
              <Columns space="medium">
                <Column>
                  <Columns space="xsmall">
                    {icon ? (
                      <Column width="content">
                        <Styles.StyledIcon
                          data-testid={TEST_ID.LINK_ICON}
                          name={icon}
                          size={iconSize}
                          className="label-icon"
                        />
                      </Column>
                    ) : null}
                    <Column>
                      <Stack space="xxsmall">
                        <Columns space={{ xs: 'medium', sm: 'large', xl: 'xlarge' }} alignY="center">
                          {/*
                           * There is an issue when the width is set to content and the link name is long so
                           * we only want to set it to content when required which is when there is a status
                           * as the producers will always use a short link in this scenario
                           */}
                          <Column width={status ? 'content' : 'flex'}>
                            <Styles.Label data-testid={TEST_ID.LINK_LABEL}>{label}</Styles.Label>
                          </Column>
                          {status ? (
                            <Column>
                              <StatusIndicator
                                status={STATUS_INDICATOR_STATUSES.INFO}
                                type={STATUS_INDICATOR_VARIANT.NO_STATUS_LIGHT}
                                data-testid={TEST_ID.LINK_STATUS}
                              >
                                {status}
                              </StatusIndicator>
                            </Column>
                          ) : null}
                        </Columns>
                        <Stack space={{ xs: 'small', sm: 'medium' }}>
                          <>{children}</>
                          {caption && (
                            <Copy variant="small" data-testid={TEST_ID.LINK_CAPTION}>
                              {caption}
                            </Copy>
                          )}
                        </Stack>
                      </Stack>
                    </Column>
                  </Columns>
                </Column>
                <Column width="content" alignY="center">
                  <Styles.RightIcon
                    hasColor={COLOURS.DARK_BLUE}
                    name={ctaIcon || ICON_NAMES.ChevronRight}
                    size="small"
                    className="cta-icon"
                  />
                </Column>
              </Columns>
            </Styles.Link>
          </Styles.ListItem>
        );
      })}
    </UnstyledList>
  );
};

LinkList.displayName = 'LinkList';
export default LinkList;
