import { useCallback, useEffect, useRef, useState, FC } from 'react';
import FocusTrap from 'focus-trap-react';
import find from 'lodash/find';
import kebabCase from 'lodash/kebabCase';

import { logout } from '@belong/utils/user';
import useRuntime from '@belong/providers/runtime';
import { ICON_NAMES, IOrganismMenuItem } from '@belong/types';
import { A11yCopy, RichText, Logo as BelongLogo, Alert, Icon, KEYBOARD_KEYS } from '@belong/ui-components';

import { COLOURS } from '@belong/themes';
import { SearchPanel } from '@belong/search';
import { IGlobalHeaderMobile } from './types';
import { GLOBAL_HEADER_TEST_ID as TEST_ID } from '../testids';
import { ARIA_CURRENT } from '../variables';
import { useHeaderVisibility } from '../hooks/useHeaderVisibility';
import * as styles from './styles';
import { HamburgerMenuIcon } from './HamburgerMenuIcon';
import { MobileLinkPanel } from './GHMobileLinkPanel';
import { GHMobileAccountButton } from './GHMobileAccountButton';
import { GlobalHeaderMobileMainMenu } from './GlobalHeaderMobileMainMenu';

const ARIA_LABEL_MENU_LINK = 'Menu';
const ARIA_LABEL_SEARCH_LINK = 'Search menu';
type IMenuBarButtons = 'navigation' | 'search';

export const GlobalHeaderMobile: FC<IGlobalHeaderMobile> = ({
  headerMenuContent,
  currentUrl,
  hideMyAccountLinks,
  isLoggedIn,
  pageAlert,
  urls,
  onExpanded
}: IGlobalHeaderMobile) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [showLinkPanel, setShowLinkPanel] = useState<boolean>(false);
  const [removeLinkPanel, setRemoveLinkPanel] = useState<boolean>(false);
  const [linkPanelContent, setLinkPanelContent] = useState<IOrganismMenuItem | undefined>(undefined);
  const [accentOriginRight, setAccentOriginRight] = useState<boolean>(false); // state to update the accent strip position
  const accentOriginRightRef = useRef<boolean>(false); // ref to handle code based desicions on accent strip position
  const offCanvasMenuRef = useRef<HTMLElement>(null);
  const offCanvasSearchRef = useRef<HTMLElement>(null);
  const offCanvasLinkPanelRef = useRef<HTMLElement>(null);
  const linkPanelRef = useRef<HTMLElement>(null);
  const accentStripRef = useRef<HTMLElement>(null);
  const topHeaderRef = useRef<HTMLDivElement>(null);
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const searchButtonRef = useRef<HTMLButtonElement>(null);
  const headerVisibility = useHeaderVisibility(topHeaderRef, styles.HEIGHT);

  const addTransitionEndListener = (element: HTMLElement, callback: () => void): void => {
    element.addEventListener('transitionend', callback);
  };

  const removeTransitionEndListener = (element: HTMLElement, callback: () => void): void => {
    element.removeEventListener('transitionend', callback);
  };

  const updateMenuUI = (menuBarButton: IMenuBarButtons) => {
    const isSearchButton = menuBarButton === 'search';
    const isNavigationButton = menuBarButton === 'navigation';

    if (isNavigationButton) {
      if (showMenu) {
        setShowMenu(false);
      } else {
        setShowSearch(false);
        setShowMenu(true);
      }
    }

    if (isSearchButton) {
      if (showSearch) {
        setShowSearch(false);
      } else {
        setShowSearch(true);
        setShowMenu(false);
      }
    }

    if (showLinkPanel) {
      if (isSearchButton) {
        setShowSearch(true);
        setShowMenu(false);
        handleHideLinksPanel();
      } else {
        setShowMenu(true);
        setShowLinkPanel(false);
        menuButtonRef.current?.focus();
      }
    }
  };

  const handleMenuBarButtonClick = (menuBarButton: IMenuBarButtons): void => {
    setAccentOriginRight(menuBarButton === 'search');
    accentOriginRightRef.current = menuBarButton === 'search';

    if (!showMenu && !showLinkPanel && !showSearch) {
      // if the accent strip is not on the expected side for the type of menu button clicked then reset it
      // we must use the actual position of the element since the state value is not yet updated

      if (accentStripRef.current) {
        // check on the current position of the accent strip
        const isAccentStripOnLeft = getComputedStyle(accentStripRef.current)
          .getPropertyValue('transform')
          .includes('-');

        // determine if the accent strip needs to be reset into position
        if (
          (isAccentStripOnLeft && menuBarButton === 'search') ||
          (!isAccentStripOnLeft && menuBarButton === 'navigation')
        ) {
          accentStripRef.current.style.opacity = '0';
          accentStripRef.current.style.transition = 'transform 0.1s';
          addTransitionEndListener(accentStripRef.current as HTMLElement, resetAccentStrip);
        } else {
          updateMenuUI(menuBarButton);
        }
      }
    } else {
      updateMenuUI(menuBarButton);
    }
  };

  const resetAccentStrip = (): void => {
    removeTransitionEndListener(accentStripRef.current as HTMLElement, resetAccentStrip);
    if (accentStripRef.current) {
      accentStripRef.current.style.removeProperty('opacity');
      accentStripRef.current.style.removeProperty('transition');
    }
    updateMenuUI(accentOriginRightRef.current ? 'search' : 'navigation');
  };

  const handleShowLinkPanel = (menuIndex: number): void => {
    // reset the opacity. It may previously have been set to 0
    if (offCanvasLinkPanelRef.current) {
      offCanvasLinkPanelRef.current.style.removeProperty('opacity');
    }
    // update the content of the second level menu panel
    setLinkPanelContent(headerMenuContent.headerMenuItems[menuIndex]);
    // animate in the second level menu panel
    setShowLinkPanel(true);
    // animate out the main menu panel
    setShowMenu(false);
  };

  const handleHideLinksPanel = (): void => {
    addTransitionEndListener(offCanvasLinkPanelRef.current as HTMLElement, resetLinkPanel);
    setRemoveLinkPanel(true);
    setShowLinkPanel(false);
  };

  const resetLinkPanel = (): void => {
    removeTransitionEndListener(offCanvasLinkPanelRef.current as HTMLElement, resetLinkPanel);
    // set the opacity to conceal the reseting animation
    if (offCanvasLinkPanelRef.current) {
      offCanvasLinkPanelRef.current.style.opacity = '0';
    }
    setRemoveLinkPanel(false);
  };

  const handleMenuClose = (): void => {
    setShowMenu(false);
    menuButtonRef.current?.focus();
  };

  const handleMenuEscape = useCallback(({ key }: KeyboardEvent): boolean | void => {
    removeTransitionEndListener(offCanvasMenuRef.current as HTMLElement, () => {});
    if (key === KEYBOARD_KEYS.ESCAPE) {
      handleMenuClose();
    }
  }, []);

  const handleLinkPanelEscape = useCallback(({ key }: KeyboardEvent): boolean | void => {
    removeTransitionEndListener(offCanvasMenuRef.current as HTMLElement, () => {});
    if (key === KEYBOARD_KEYS.ESCAPE) {
      setShowLinkPanel(false);
    }
  }, []);

  const handleSearchEscape = useCallback(({ key }: KeyboardEvent): boolean | void => {
    removeTransitionEndListener(offCanvasSearchRef.current as HTMLElement, () => {});
    if (key === KEYBOARD_KEYS.ESCAPE) {
      setShowSearch(false);
    }
  }, []);

  const { isDesktopViewport } = useRuntime();

  useEffect(() => {
    if (isDesktopViewport) {
      handleMenuClose();
    }
  }, [isDesktopViewport]);

  // this useEffect is used to position to the off canvas panels
  useEffect(() => {
    if (onExpanded !== undefined) {
      onExpanded(showMenu || showLinkPanel || showSearch);
    }

    if (offCanvasMenuRef?.current !== null) {
      if (topHeaderRef.current) {
        const topHeaderHeight = topHeaderRef.current.getBoundingClientRect().height;
        offCanvasMenuRef.current.style.top = `${topHeaderHeight}px`;
      }
      window.addEventListener('keydown', handleMenuEscape);
    }

    if (offCanvasLinkPanelRef?.current !== null) {
      if (topHeaderRef.current) {
        const topHeaderHeight = topHeaderRef.current.getBoundingClientRect().height;
        offCanvasLinkPanelRef.current.style.top = `${topHeaderHeight}px`;
      }
      window.addEventListener('keydown', handleLinkPanelEscape);
    }

    if (offCanvasSearchRef?.current !== null) {
      if (topHeaderRef.current) {
        const topHeaderHeight = topHeaderRef.current.getBoundingClientRect().height;
        offCanvasSearchRef.current.style.top = `${topHeaderHeight}px`;
      }
      window.addEventListener('keydown', handleSearchEscape);
    }

    return (): void => {
      window.removeEventListener('keydown', handleMenuEscape);
      window.removeEventListener('keydown', handleLinkPanelEscape);
      window.removeEventListener('keydown', handleSearchEscape);
    };
  }, [handleLinkPanelEscape, handleMenuEscape, handleSearchEscape, onExpanded, showLinkPanel, showMenu, showSearch]);

  const handleLogoutClick = (): void => {
    handleMenuClose();
    logout('/');
  };

  /**
   * This effect is a bandaid fix for Safari on iOS *intermittantly* failing
   * to animate `position: fixed` elements properly.
   */
  useEffect(() => {
    const onTransitionEnd = () => {
      if (offCanvasMenuRef.current && accentStripRef.current) {
        // force these elements to repaint
        offCanvasMenuRef.current.style.display = 'none';
        accentStripRef.current.style.display = 'none';

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        offCanvasMenuRef.current.offsetHeight;
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        accentStripRef.current.offsetHeight;

        offCanvasMenuRef.current.style.display = 'flex';
        accentStripRef.current.style.display = 'flex';
      }
    };

    offCanvasMenuRef.current?.addEventListener('transitionend', onTransitionEnd);

    return () => offCanvasMenuRef.current?.removeEventListener('transitionend', onTransitionEnd);
  }, [offCanvasMenuRef, accentStripRef]);

  const currentOrCurrentChild = (menuItem: IOrganismMenuItem): typeof ARIA_CURRENT | undefined => {
    // check the child links first
    if (menuItem.navGroup1 || menuItem.navGroup2) {
      // combines navGroup1 and navGroup2 into a single object
      const navGroups = [...(menuItem.navGroup1 || []), ...(menuItem.navGroup2 || [])];

      // does the new navGroups object have a navLink with an href that matches the current path
      return navGroups.some(navGroupItem => find(navGroupItem.navLinks, navLink => currentUrl === navLink.href))
        ? ARIA_CURRENT
        : undefined;
    }
    // if there are no child links, check the parent link
    return menuItem.url && currentUrl === menuItem.url.href ? ARIA_CURRENT : undefined;
  };

  return (
    <>
      {pageAlert && (
        <Alert aria-hidden {...pageAlert}>
          {pageAlert.content ? <RichText html={pageAlert.content} /> : pageAlert.children}
        </Alert>
      )}

      <styles.Root>
        <styles.NavHeader data-testid={TEST_ID.MOBILE} isHidden={headerVisibility.isHidden} ref={topHeaderRef}>
          <styles.RelativeContainer ref={topHeaderRef}>
            {pageAlert && (
              <Alert {...pageAlert}>
                {pageAlert.content ? <RichText html={pageAlert.content} /> : pageAlert.children}
              </Alert>
            )}
            <FocusTrap active={showMenu || showSearch || showLinkPanel}>
              <styles.WrapperModal>
                <styles.HeaderBarMobile>
                  <styles.MenuButton
                    onClick={(): void => handleMenuBarButtonClick('navigation')}
                    ref={menuButtonRef}
                    aria-expanded={showMenu}
                    aria-label={ARIA_LABEL_MENU_LINK}
                    data-testid={TEST_ID.MOBILE_MENU_HAMBURGER}
                  >
                    <HamburgerMenuIcon open={showMenu} secondLevelOpen={showLinkPanel} />
                    <A11yCopy data-testid={TEST_ID.A11YCOPY}>
                      {showMenu ? headerMenuContent.a11yCloseNavigation : headerMenuContent.a11yOpenNavigation}
                    </A11yCopy>
                  </styles.MenuButton>
                  <styles.LinkLogo
                    href={headerMenuContent.belongHome.href}
                    onClick={handleMenuClose}
                    data-testid={TEST_ID.BUTTON_HOME}
                  >
                    <BelongLogo />
                    <A11yCopy>{headerMenuContent.belongHome.label}</A11yCopy>
                  </styles.LinkLogo>
                  <styles.SearchButton
                    onClick={(): void => handleMenuBarButtonClick('search')}
                    ref={searchButtonRef}
                    aria-expanded={showSearch}
                    aria-label={ARIA_LABEL_SEARCH_LINK}
                    data-testid={TEST_ID.MOBILE_MENU_SEARCH_BUTTON}
                  >
                    {showSearch ? (
                      <Icon name={ICON_NAMES.Close} hasColor={COLOURS.BELONG_BLUE} />
                    ) : (
                      <Icon name={ICON_NAMES.Search} hasColor={COLOURS.BELONG_BLUE} />
                    )}
                  </styles.SearchButton>
                </styles.HeaderBarMobile>

                <styles.OffCanvasMenu
                  data-testid={TEST_ID.OFFCANVAS}
                  aria-hidden={!showMenu}
                  isExpanded={showMenu}
                  ref={offCanvasMenuRef}
                  aria-label="Menu modal"
                  tabIndex={showMenu ? -1 : undefined}
                  aria-labelledby={TEST_ID.MOBILE_MENU_HAMBURGER}
                >
                  <styles.ModalAccessibilityHeader id="modalMenuHeader" tabIndex={-1}>
                    Start of menu modal
                  </styles.ModalAccessibilityHeader>

                  <GlobalHeaderMobileMainMenu
                    isLoggedIn={isLoggedIn}
                    hideMyAccountLinks={hideMyAccountLinks}
                    currentUrl={currentUrl}
                    urls={urls}
                    headerMenuContent={headerMenuContent}
                    handleOffCanvasClose={handleMenuClose}
                    handleShowLinkPanel={handleShowLinkPanel}
                    currentOrCurrentChild={currentOrCurrentChild}
                  />

                  {/* Log in & Log out */}
                  <GHMobileAccountButton
                    handleLogoutClick={handleLogoutClick}
                    handleClose={handleMenuClose}
                    isLoggedIn={isLoggedIn}
                    urls={urls}
                    loginLabel={headerMenuContent.loginLabel}
                    logoutLabel={headerMenuContent.logoutLabel}
                    loginIcon={headerMenuContent.loginIcon}
                    logoutIcon={headerMenuContent.logoutIcon}
                    currentUrl={currentUrl}
                  />
                </styles.OffCanvasMenu>

                <styles.OffCanvasSearch
                  data-testid={TEST_ID.OFFCANVAS_SEARCH}
                  aria-hidden={!showSearch}
                  isExpanded={showSearch}
                  ref={offCanvasSearchRef}
                  aria-label="Search modal"
                  tabIndex={showSearch ? -1 : undefined}
                  aria-labelledby={TEST_ID.BUTTON_SEARCH}
                >
                  <styles.ModalAccessibilityHeader id="modalSearchHeader" tabIndex={-1}>
                    Start of search modal
                  </styles.ModalAccessibilityHeader>

                  <styles.StyledSearch>
                    <SearchPanel
                      callback={() => handleMenuBarButtonClick('search')}
                      data-testid="mobile-search-panel"
                    />
                  </styles.StyledSearch>
                </styles.OffCanvasSearch>

                <styles.OffCanvasLinkPanel
                  data-testid={TEST_ID.OFFCANVASLINKPANEL}
                  aria-hidden={!showLinkPanel}
                  isCenter={showLinkPanel}
                  offCanvasLeft={removeLinkPanel}
                  ref={offCanvasLinkPanelRef}
                  aria-label="Menu links"
                  tabIndex={showLinkPanel ? -1 : undefined}
                  aria-labelledby={`global-header-button-${kebabCase(linkPanelContent?.parentLabel).toLowerCase()}`}
                >
                  <MobileLinkPanel
                    linkPanelContent={linkPanelContent && linkPanelContent}
                    currentUrl={currentUrl && currentUrl}
                    onLinkClick={() => handleHideLinksPanel()}
                    ref={linkPanelRef}
                  />
                </styles.OffCanvasLinkPanel>
              </styles.WrapperModal>
            </FocusTrap>
            <styles.AccentStrip
              isExpanded={showMenu || showSearch || showLinkPanel}
              originRight={accentOriginRight}
              ref={accentStripRef}
            />
          </styles.RelativeContainer>
        </styles.NavHeader>
      </styles.Root>
    </>
  );
};

GlobalHeaderMobile.displayName = 'GlobalHeaderMobile';
