/**
 * Salesforce LiveAgent
 *
 * @see https://belongranda.atlassian.net/wiki/spaces/BEL/pages/1231257991/Salesforce+Live+Chat+Pieces
 * @see https://developer.salesforce.com/docs/atlas.en-us.live_agent_dev.meta/live_agent_dev/live_agent_automated_chat_invitations_code_sample.htm
 * @see https://bitbucket.org/belongadmin/ui/src/develop/styleguide/source/habitat/js/components/ChatPopup.js
 */

import { useCallback, useEffect, useState } from 'react';
import { ENVS } from '@belong/types/envs';
import { getCurrentEnvironment } from '@belong/utils/env/env';
import { logger } from '@belong/logging/logger';
import { ICancellablePromise, makeWeakCancelable } from '@belong/utils/promise';
import { getSessionId, isLiveAgentReady } from './utils';

/**
 * Load the <script src="liveagent.js">
 */
const loadLibScript = (baseUrl: string, libVersion: string | number): Promise<HTMLScriptElement> => {
  return new Promise(resolve => {
    const libScript = document.createElement('script');
    libScript.src = `${baseUrl}/content/g/js/${libVersion}/deployment.js`;
    libScript.onload = (): void => resolve(libScript);
    document.body.appendChild(libScript);
  });
};

interface IProps {
  /**
   * Whether livechat should show on this page (when agents are available)
   */
  enabled: boolean;

  /**
   * Salesforce / LiveAgent config variables
   */
  sdkBaseUrl: string;
  deploymentId: string;
  organizationId: string;
  buttonId: string;

  /**
   * A reference to the DOM node that should show/hide based on agent availability
   */
  theButton?: HTMLElement;
}

interface IHook {
  sessionId?: string;
  startChat: () => void;
  isAgentAvailable: boolean;
}

const useLiveAgent = ({ buttonId, deploymentId, organizationId, sdkBaseUrl, enabled, theButton }: IProps): IHook => {
  const [sessionId, setSessionId] = useState<string>();
  const [isAgentAvailable, setIsAgentAvailable] = useState<boolean>(false);

  const kickOff = (): void => {
    const { liveagent } = window as any;

    logger.debug(`Registering button with id "${buttonId}" to have its visibility managed by LiveAgent.`);
    /**
     * (show when agents are available for chat, hide when not).
     * This automatically applies a `display: none` on the button when
     * no agents are available, and removes the `display: none` from
     * the button when there are agents available.
     */
    liveagent.showWhenOnline(buttonId, theButton);

    liveagent.addButtonEventHandler(buttonId, buttonEvent => {
      /**
       * If SF says the Agent is online, it will trigger this callback
       * with `buttonEvent = "AVAILABLE"`, so we inform React state of
       * the change.
       */
      if (buttonEvent === liveagent.BUTTON_EVENT.BUTTON_AVAILABLE) {
        setIsAgentAvailable(true);
      }

      /**
       * If SF says the Agent is offline, it will trigger this callback
       * with `buttonEvent = "UNAVAILABLE"`, so we inform React state of
       * the change.
       */
      if (buttonEvent === liveagent.BUTTON_EVENT.BUTTON_UNAVAILABLE) {
        setIsAgentAvailable(false);
      }
    });
  };

  /**
   * Push an initialization function into a global queue which LiveAgent
   * will pick up and iterate through once it boots up
   */
  useEffect(() => {
    /**
     * `kickOff()` needs `theButton` to exist in the closure scope
     * by the time its reference is first used so we have to wait
     * until it's available.
     */
    if (theButton) {
      const Window = window as any;

      /* eslint-disable no-underscore-dangle */

      if (!Window._laq) {
        Window._laq = [];
      }

      Window._laq.push(kickOff);

      /* eslint-enable no-underscore-dangle */
    }
  }, [theButton]);

  const startChatCallback = useCallback(() => {
    if (!theButton) {
      logger.error('No button DOM node found to initiate a live chat session.');
    }
    /**
     * @see https://developer.salesforce.com/docs/atlas.en-us.live_agent_dev.meta/live_agent_dev/live_agent_startChat_invocations.htm
     */
    (window as any).liveagent.startChat(buttonId);
  }, [buttonId, theButton]);

  /**
   * When enabled, load the lib if not already loaded.
   * This is a one-way door as you cannot "clean up" loading the SF scripts.
   */
  useEffect(() => {
    let libScriptLoader: ICancellablePromise<boolean> | undefined;

    if (enabled) {
      if (!isLiveAgentReady() && !!theButton) {
        libScriptLoader = makeWeakCancelable(loadLibScript(sdkBaseUrl, '41.0'));
        libScriptLoader.promise.then(isCancelled => {
          if (!isCancelled) {
            /**
             * Turn on logging (lower envs only)
             *
             * @see https://developer.salesforce.com/docs/atlas.en-us.live_agent_dev.meta/live_agent_dev/live_agent_logging_API_enableLogging.htm
             */
            if ([ENVS.DEVELOPMENT, ENVS.AT].includes(getCurrentEnvironment())) {
              (window as any).liveagent.enableLogging();
            }

            /**
             * Get access to the Salesforce SDK instance
             */
            const { liveagent } = window as any;

            liveagent.init(`${sdkBaseUrl}/chat`, deploymentId, organizationId);

            /**
             * Signal the conclusion of the initialisation
             */
            setSessionId(getSessionId());
          }
        });
      }
    }

    return (): void => {
      /**
       * Attempt to cancel loading the <script> if it hasn't already
       * loaded. If it has, there's nothing we can do. You cannot un-
       * initialise the SF script (and all its global variables and
       * event listeners).
       */
      if (libScriptLoader) {
        libScriptLoader.cancel();
      }
    };
  }, [enabled, sdkBaseUrl, buttonId, deploymentId, organizationId, theButton]);

  return {
    sessionId,
    startChat: startChatCallback,
    isAgentAvailable
  };
};

export default useLiveAgent;
