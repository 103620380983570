import * as React from 'react';
import { ReactNode } from 'react';

import { usePlatformConfig } from '@belong/utils/device/device-bridge/usePlatformConfig';
import { ID, IPageAlert, INavLinkProps, IHeaderMenu, IFooterMenu } from '@belong/types';
import Breadcrumbs from '@belong/ui-components/components/navigation/Breadcrumbs';

import GlobalHeaderContainer from '../containers/GlobalHeaderContainer';
import GlobalFooterContainer from '../containers/GlobalFooterContainer';

import { Main } from './styles';
import LiveChat from '../LiveChat';

interface IProps {
  headerMenuContent: IHeaderMenu;
  footerMenuContent: IFooterMenu;
  pageAlert?: IPageAlert;
  children?: ReactNode;
  shouldHaveLiveChat: boolean;
  breadcrumbs?: INavLinkProps[];
}

const LayoutShell: React.FC<IProps> = ({
  children,
  headerMenuContent,
  footerMenuContent,
  pageAlert,
  shouldHaveLiveChat,
  breadcrumbs
}: IProps) => {
  const { isNativeApp } = usePlatformConfig();

  const main = <Main id={ID.CONTENT}>{children}</Main>;

  if (isNativeApp) {
    return main;
  }

  return (
    <>
      <GlobalHeaderContainer
        headerMenuContent={headerMenuContent}
        pageAlert={pageAlert}
        showBackToTopTrigger={!shouldHaveLiveChat}
      >
        {breadcrumbs?.length && <Breadcrumbs links={breadcrumbs} />}
        {main}
        <GlobalFooterContainer {...footerMenuContent} />
        <LiveChat enabled={shouldHaveLiveChat} />
      </GlobalHeaderContainer>
    </>
  );
};

LayoutShell.displayName = 'LayoutShell';

export default LayoutShell;
