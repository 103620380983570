import styled, { css } from 'styled-components';

import { COLOURS } from '@belong/themes';
import { IAccentStrip } from './AccentStrip';

export enum ACCENTSTRIP_VARIANTS {
  DISCO_PINK = 'DISCO_PINK',
  BLUE = 'BLUE',
  PINK = 'PINK',
  PURPLE = 'PURPLE',
  MOSAIC = 'MOSAIC',
  ALT_ORANGE = 'ALT_ORANGE',
  FOIL_SLICK = 'FOIL_SLICK',
  REVERSE_FOIL_SLICK = 'REVERSE_FOIL_SLICK',
  WHITE = 'WHITE',
  PEPPERMINT_CRISP = 'PEPPERMINT_CRISP',
  PASSION_POP = 'PASSION_POP',
  UNAVAILABLE = 'UNAVAILABLE',
  GREEN = 'GREEN'
}

const variants = {
  [ACCENTSTRIP_VARIANTS.PASSION_POP]: {
    solid: '#C175FF'
  },
  [ACCENTSTRIP_VARIANTS.DISCO_PINK]: {
    solid: '#FF4BFF',
    xsDefaultGradient: 'linear-gradient(270deg, #FF4BFF 30%, #41B6E6 70%)',
    xsActiveGradient: 'linear-gradient(90deg, #FF4BFF 30%, #41B6E6 70%)',

    lgDefaultGradient: 'linear-gradient(180deg, #FF4BFF 30%, #41B6E6 70%)',
    lgActiveGradient: 'linear-gradient(0deg, #FF4BFF 30%, #41B6E6 70%)'
  },
  [ACCENTSTRIP_VARIANTS.BLUE]: {
    solid: COLOURS.BELONG_BLUE,
    xsDefaultGradient: COLOURS.BELONG_BLUE,
    xsActiveGradient:
      'linear-gradient(90deg, #F4B480 0%, #FF4BFF 20%, #C175FF 40%, #41B6E6 60%, #38F29E 80%, #C7F238 100%)',

    lgDefaultGradient: COLOURS.BELONG_BLUE,
    lgActiveGradient:
      'linear-gradient(90deg, #F4B480 0%, #FF4BFF 20%, #C175FF 40%, #41B6E6 60%, #38F29E 80%, #C7F238 100%)'
  },
  [ACCENTSTRIP_VARIANTS.PINK]: {
    xsDefaultGradient: COLOURS.DISCO_PINK,
    xsActiveGradient:
      'linear-gradient(90deg, #F4B480 0%, #FF4BFF 20%, #C175FF 40%, #41B6E6 60%, #38F29E 80%, #C7F238 100%)',

    lgDefaultGradient: COLOURS.DISCO_PINK,
    lgActiveGradient:
      'linear-gradient(90deg, #F4B480 0%, #FF4BFF 20%, #C175FF 40%, #41B6E6 60%, #38F29E 80%, #C7F238 100%)'
  },
  [ACCENTSTRIP_VARIANTS.PURPLE]: {
    xsDefaultGradient: COLOURS.PURPLE,
    xsActiveGradient:
      'linear-gradient(90deg, #F4B480 0%, #FF4BFF 20%, #C175FF 40%, #41B6E6 60%, #38F29E 80%, #C7F238 100%)',

    lgDefaultGradient: COLOURS.PURPLE,
    lgActiveGradient:
      'linear-gradient(90deg, #F4B480 0%, #FF4BFF 20%, #C175FF 40%, #41B6E6 60%, #38F29E 80%, #C7F238 100%)'
  },
  [ACCENTSTRIP_VARIANTS.MOSAIC]: {
    xsDefaultGradient: COLOURS.MOSAIC,
    xsActiveGradient:
      'linear-gradient(90deg, #F4B480 0%, #FF4BFF 20%, #C175FF 40%, #41B6E6 60%, #38F29E 80%, #C7F238 100%)',

    lgDefaultGradient: COLOURS.MOSAIC,
    lgActiveGradient:
      'linear-gradient(90deg, #F4B480 0%, #FF4BFF 20%, #C175FF 40%, #41B6E6 60%, #38F29E 80%, #C7F238 100%)'
  },
  [ACCENTSTRIP_VARIANTS.ALT_ORANGE]: {
    xsDefaultGradient: COLOURS.ALT_ORANGE,
    xsActiveGradient:
      'linear-gradient(90deg, #F4B480 0%, #FF4BFF 20%, #C175FF 40%, #41B6E6 60%, #38F29E 80%, #C7F238 100%)',

    lgDefaultGradient: COLOURS.ALT_ORANGE,
    lgActiveGradient:
      'linear-gradient(90deg, #F4B480 0%, #FF4BFF 20%, #C175FF 40%, #41B6E6 60%, #38F29E 80%, #C7F238 100%)'
  },
  [ACCENTSTRIP_VARIANTS.FOIL_SLICK]: {
    solid: 'linear-gradient(90deg, #F4B480 0%, #FF4BFF 20%, #C175FF 40%, #41B6E6 60%, #38F29E 80%, #C7F238 100%)',
    xsDefaultGradient:
      'linear-gradient(90deg, #C7F238 0%, #38F29E 20%, #41B6E6 40%, #C175FF 60%, #FF4BFF 80%, #F4B480 100%)',
    xsActiveGradient:
      'linear-gradient(90deg, #F4B480 0%, #FF4BFF 20%, #C175FF 40%, #41B6E6 60%, #38F29E 80%, #C7F238 100%)',

    lgDefaultGradient:
      'linear-gradient(90deg, #C7F238 0%, #38F29E 20%, #41B6E6 40%, #C175FF 60%, #FF4BFF 80%, #F4B480 100%)',
    lgActiveGradient:
      'linear-gradient(90deg, #F4B480 0%, #FF4BFF 20%, #C175FF 40%, #41B6E6 60%, #38F29E 80%, #C7F238 100%)',
    vertical: {
      xsDefaultGradient:
        'linear-gradient(0deg, #F4B480 0%, #FF4BFF 20%, #C175FF 40%, #41B6E6 60%, #38F29E 80%, #C7F238 100%)',
      xsActiveGradient:
        'linear-gradient(0deg, #F4B480 0%, #FF4BFF 20%, #C175FF 40%, #41B6E6 60%, #38F29E 80%, #C7F238 100%)',

      lgDefaultGradient:
        'linear-gradient(0deg, #C7F238 0%, #38F29E 20%, #41B6E6 40%, #C175FF 60%, #FF4BFF 80%, #F4B480 100%)',
      lgActiveGradient:
        'linear-gradient(0deg, #F4B480 0%, #FF4BFF 20%, #C175FF 40%, #41B6E6 60%, #38F29E 80%, #C7F238 100%)'
    }
  },
  [ACCENTSTRIP_VARIANTS.WHITE]: {
    xsDefaultGradient: COLOURS.WHITE,
    xsActiveGradient:
      'linear-gradient(90deg, #F4B480 0%, #FF4BFF 20%, #C175FF 40%, #41B6E6 60%, #38F29E 80%, #C7F238 100%)',
    lgDefaultGradient: COLOURS.WHITE,
    lgActiveGradient:
      'linear-gradient(90deg, #F4B480 0%, #FF4BFF 20%, #C175FF 40%, #41B6E6 60%, #38F29E 80%, #C7F238 100%)'
  },
  [ACCENTSTRIP_VARIANTS.REVERSE_FOIL_SLICK]: {
    xsDefaultGradient:
      'linear-gradient(90deg, #F4B480 0%, #FF4BFF 20%, #C175FF 40%, #41B6E6 60%, #38F29E 80%, #C7F238 100%)',
    xsActiveGradient:
      'linear-gradient(90deg, #C7F238 0%, #38F29E 20%, #41B6E6 40%, #C175FF 60%, #FF4BFF 80%, #F4B480 100%)'
  },
  [ACCENTSTRIP_VARIANTS.PEPPERMINT_CRISP]: {
    solid: '#38F29E',
    xsDefaultGradient:
      'linear-gradient(90deg, #38F29E 0%, #FF4BFF 20%, #C175FF 40%, #41B6E6 60%, #38F29E 80%, #C7F238 100%)',
    xsActiveGradient:
      'linear-gradient(90deg, #C7F238 0%, #38F29E 20%, #41B6E6 40%, #C175FF 60%, #FF4BFF 80%, #F4B480 100%)'
  },
  [ACCENTSTRIP_VARIANTS.UNAVAILABLE]: {
    solid: COLOURS.GREY_500
  },
  [ACCENTSTRIP_VARIANTS.GREEN]: {
    solid: COLOURS.DARK_GREEN
  }
};

const verticalStyles = css<IAccentStrip>`
  width: ${p => (p.isSmall ? '4px' : '8px')};
  height: 100%;
  background: ${p => p.variant && (variants[p.variant] as any)?.vertical?.xsDefaultGradient};
  &::after {
    width: ${p => (p.isSmall ? '4px' : '8px')};
    height: 100%;
    background: ${p => p.variant && (variants[p.variant] as any)?.vertical?.xsActiveGradient};
  }
`;

const verticalFlexStartStyles = css`
  border-radius: 8px 0 0 8px;
  &::after {
    border-radius: 8px 0 0 8px;
  }
`;

const verticalFlexEndStyles = css`
  border-radius: 0 8px 8px 0;
  &::after {
    border-radius: 0 8px 8px 0;
  }
`;

const horizontalStyles = css<IAccentStrip>`
  width: 100%;
  height: ${p => (p.isSmall ? '4px' : '8px')};
  background: ${p => {
    const { variant, isSolid } = p;
    if (isSolid && variant) {
      return (variants[variant] as any)?.solid;
    }
    if (variant !== undefined) {
      return (variants[variant] as any)?.xsDefaultGradient;
    }
  }};

  &::after {
    width: 100%;
    height: ${p => (p.isSmall ? '4px' : '8px')};
    background: ${p => p.variant && (variants[p.variant] as any)?.xsActiveGradient};
  }
`;

const horizontalFlexStartStyles = css<IAccentStrip>`
  border-radius: ${p => (p.isBottom ? '0 0 0 0' : '8px 8px 0 0')};
  &::after {
    border-radius: ${p => (p.isBottom ? '0 0 0 0' : '8px 8px 0 0')};
  }
`;

const horizontalFlexEndStyles = css<IAccentStrip>`
  border-radius: ${p => (p.isBottom ? '0 0 0 0' : '0 0 8px 8px')};
  &::after {
    border-radius: ${p => (p.isBottom ? '0 0 0 0' : '0 0 8px 8px')};
  }
`;

/**
 * Provide support for vertical AccentStrips, currently only for FOIL_SLICK.
 * This is done by switching between the width and height based on whether the
 * foil is vertical or horizontal (isVertical prop).
 * We use a different background for vertical as the gradients should now be vertical.
 * We also have different logic for bord radius as if the accent is vertical and
 * depending on whether it is on the right (isFlexEnd) or on the left (!isFlexEnd)
 * different border radii should be set/unset (8px/0px).
 */
export const StyledAccentStrip = styled.div<IAccentStrip>`
  ${p => (p.isVertical ? verticalStyles : horizontalStyles)};
  ${p => p.isVertical && (p.isFlexEnd ? verticalFlexEndStyles : verticalFlexStartStyles)};
  ${p => !p.isVertical && (p.isFlexEnd ? horizontalFlexEndStyles : horizontalFlexStartStyles)};

  position: relative;
  margin-top: ${props => (props.isFlexEnd ? 'auto' : undefined)};

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;

    opacity: ${props => (props.hasOpacity ? 1 : 0)};
    transition: opacity 0.3s ease-in;
  }
`;

export const AccentStripHoverAndFocusStyles = css`
  &:hover ${StyledAccentStrip}, &.selected ${StyledAccentStrip} {
    &::after {
      opacity: 1;
    }
  }

  &:hover.current ${StyledAccentStrip}, &.focused.current ${StyledAccentStrip} {
    &::after {
      opacity: 0;
    }
  }

  &:focus-within ${StyledAccentStrip} {
    &::after {
      opacity: 1;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
`;
