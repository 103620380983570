// This file is generated by build.js
// DO NOT EDIT IT MANUALLY
import dynamic from 'next/dynamic';
export const ICONS = {
    Activation: dynamic(() => import('../output/Activation')),
    AddCircle: dynamic(() => import('../output/AddCircle')),
    AddUser: dynamic(() => import('../output/AddUser')),
    Add: dynamic(() => import('../output/Add')),
    ArrowDown: dynamic(() => import('../output/ArrowDown')),
    ArrowUp: dynamic(() => import('../output/ArrowUp')),
    Avatar: dynamic(() => import('../output/Avatar')),
    Award: dynamic(() => import('../output/Award')),
    BoltActive: dynamic(() => import('../output/BoltActive')),
    BoltInactive: dynamic(() => import('../output/BoltInactive')),
    Mastercard__Brand: dynamic(() => import('../output/Mastercard__Brand')),
    PayPal__Brand: dynamic(() => import('../output/PayPal__Brand')),
    PayPalVertical__Brand: dynamic(() => import('../output/PayPalVertical__Brand')),
    Visa__Brand: dynamic(() => import('../output/Visa__Brand')),
    Calendar: dynamic(() => import('../output/Calendar')),
    Chat: dynamic(() => import('../output/Chat')),
    ChevronDown: dynamic(() => import('../output/ChevronDown')),
    ChevronLeft: dynamic(() => import('../output/ChevronLeft')),
    ChevronRight: dynamic(() => import('../output/ChevronRight')),
    ChevronUp: dynamic(() => import('../output/ChevronUp')),
    CloseCircle: dynamic(() => import('../output/CloseCircle')),
    Close: dynamic(() => import('../output/Close')),
    ComponentError: dynamic(() => import('../output/ComponentError')),
    Copy: dynamic(() => import('../output/Copy')),
    CreditCard: dynamic(() => import('../output/CreditCard')),
    Danger: dynamic(() => import('../output/Danger')),
    Data: dynamic(() => import('../output/Data')),
    Download: dynamic(() => import('../output/Download')),
    Email: dynamic(() => import('../output/Email')),
    Calendar__Emoji: dynamic(() => import('../output/Calendar__Emoji')),
    CheckMark__Emoji: dynamic(() => import('../output/CheckMark__Emoji')),
    Diamond__Emoji: dynamic(() => import('../output/Diamond__Emoji')),
    Dolphin__Emoji: dynamic(() => import('../output/Dolphin__Emoji')),
    FaceCowboyHat__Emoji: dynamic(() => import('../output/FaceCowboyHat__Emoji')),
    FaceJoyTears__Emoji: dynamic(() => import('../output/FaceJoyTears__Emoji')),
    FaceSmilingSunglasses__Emoji: dynamic(() => import('../output/FaceSmilingSunglasses__Emoji')),
    Fire__Emoji: dynamic(() => import('../output/Fire__Emoji')),
    Ghost__Emoji: dynamic(() => import('../output/Ghost__Emoji')),
    GlobeAmerica__Emoji: dynamic(() => import('../output/GlobeAmerica__Emoji')),
    GlobeNetwork__Emoji: dynamic(() => import('../output/GlobeNetwork__Emoji')),
    Globe__Emoji: dynamic(() => import('../output/Globe__Emoji')),
    GrimacingFace__Emoji: dynamic(() => import('../output/GrimacingFace__Emoji')),
    HammerPick__Emoji: dynamic(() => import('../output/HammerPick__Emoji')),
    Indonesia__Emoji: dynamic(() => import('../output/Indonesia__Emoji')),
    LightningBolt__Emoji: dynamic(() => import('../output/LightningBolt__Emoji')),
    NetworkBars__Emoji: dynamic(() => import('../output/NetworkBars__Emoji')),
    NewZealand__Emoji: dynamic(() => import('../output/NewZealand__Emoji')),
    Phone__Emoji: dynamic(() => import('../output/Phone__Emoji')),
    Plus__Emoji: dynamic(() => import('../output/Plus__Emoji')),
    Shrimp__Emoji: dynamic(() => import('../output/Shrimp__Emoji')),
    Singapore__Emoji: dynamic(() => import('../output/Singapore__Emoji')),
    Star__Emoji: dynamic(() => import('../output/Star__Emoji')),
    Telephone__Emoji: dynamic(() => import('../output/Telephone__Emoji')),
    UnitedKingdom__Emoji: dynamic(() => import('../output/UnitedKingdom__Emoji')),
    USA__Emoji: dynamic(() => import('../output/USA__Emoji')),
    EyeHide: dynamic(() => import('../output/EyeHide')),
    Facebook: dynamic(() => import('../output/Facebook')),
    Hotspot: dynamic(() => import('../output/Hotspot')),
    Info: dynamic(() => import('../output/Info')),
    Instagram: dynamic(() => import('../output/Instagram')),
    International: dynamic(() => import('../output/International')),
    Internet: dynamic(() => import('../output/Internet')),
    Invoices: dynamic(() => import('../output/Invoices')),
    Laptop: dynamic(() => import('../output/Laptop')),
    LinkedIn: dynamic(() => import('../output/LinkedIn')),
    LogIn: dynamic(() => import('../output/LogIn')),
    LogOut: dynamic(() => import('../output/LogOut')),
    Map: dynamic(() => import('../output/Map')),
    Media: dynamic(() => import('../output/Media')),
    Menu: dynamic(() => import('../output/Menu')),
    Messaging: dynamic(() => import('../output/Messaging')),
    Minimize: dynamic(() => import('../output/Minimize')),
    Mobile: dynamic(() => import('../output/Mobile')),
    More: dynamic(() => import('../output/More')),
    Moving: dynamic(() => import('../output/Moving')),
    Music: dynamic(() => import('../output/Music')),
    MyServices: dynamic(() => import('../output/MyServices')),
    NewWindow: dynamic(() => import('../output/NewWindow')),
    PaperClip: dynamic(() => import('../output/PaperClip')),
    PaperPlane: dynamic(() => import('../output/PaperPlane')),
    Password: dynamic(() => import('../output/Password')),
    Payment: dynamic(() => import('../output/Payment')),
    Phone: dynamic(() => import('../output/Phone')),
    Plus: dynamic(() => import('../output/Plus')),
    Profile: dynamic(() => import('../output/Profile')),
    Recycling: dynamic(() => import('../output/Recycling')),
    Remove: dynamic(() => import('../output/Remove')),
    Roaming: dynamic(() => import('../output/Roaming')),
    Search: dynamic(() => import('../output/Search')),
    Settings: dynamic(() => import('../output/Settings')),
    Sim: dynamic(() => import('../output/Sim')),
    SpeedHigh: dynamic(() => import('../output/SpeedHigh')),
    SpeedLow: dynamic(() => import('../output/SpeedLow')),
    SpeedMedium: dynamic(() => import('../output/SpeedMedium')),
    Account__Spot: dynamic(() => import('../output/Account__Spot')),
    ActivateSim__Spot: dynamic(() => import('../output/ActivateSim__Spot')),
    AddMobile__Spot: dynamic(() => import('../output/AddMobile__Spot')),
    AddSim__Spot: dynamic(() => import('../output/AddSim__Spot')),
    AwardWinner__Spot: dynamic(() => import('../output/AwardWinner__Spot')),
    Calendar__Spot: dynamic(() => import('../output/Calendar__Spot')),
    Calls__Spot: dynamic(() => import('../output/Calls__Spot')),
    CarbonNeutral__Spot: dynamic(() => import('../output/CarbonNeutral__Spot')),
    Chat__Spot: dynamic(() => import('../output/Chat__Spot')),
    CreditCard__Spot: dynamic(() => import('../output/CreditCard__Spot')),
    Cross__Spot: dynamic(() => import('../output/Cross__Spot')),
    CurrentPlan__Spot: dynamic(() => import('../output/CurrentPlan__Spot')),
    DataGift__Spot: dynamic(() => import('../output/DataGift__Spot')),
    Email__Spot: dynamic(() => import('../output/Email__Spot')),
    Error__Spot: dynamic(() => import('../output/Error__Spot')),
    International__Spot: dynamic(() => import('../output/International__Spot')),
    Internet__Spot: dynamic(() => import('../output/Internet__Spot')),
    Invoice__Spot: dynamic(() => import('../output/Invoice__Spot')),
    Map__Spot: dynamic(() => import('../output/Map__Spot')),
    Media__Spot: dynamic(() => import('../output/Media__Spot')),
    Mobile__Spot: dynamic(() => import('../output/Mobile__Spot')),
    MobileServicePrivate__Spot: dynamic(() => import('../output/MobileServicePrivate__Spot')),
    MobileServicePublic__Spot: dynamic(() => import('../output/MobileServicePublic__Spot')),
    Music__Spot: dynamic(() => import('../output/Music__Spot')),
    Nbn__Spot: dynamic(() => import('../output/Nbn__Spot')),
    NetHealth__Spot: dynamic(() => import('../output/NetHealth__Spot')),
    Network__Spot: dynamic(() => import('../output/Network__Spot')),
    NewPlan__Spot: dynamic(() => import('../output/NewPlan__Spot')),
    Number1__Spot: dynamic(() => import('../output/Number1__Spot')),
    Number2__Spot: dynamic(() => import('../output/Number2__Spot')),
    Number3__Spot: dynamic(() => import('../output/Number3__Spot')),
    Number4__Spot: dynamic(() => import('../output/Number4__Spot')),
    Number5__Spot: dynamic(() => import('../output/Number5__Spot')),
    Number6__Spot: dynamic(() => import('../output/Number6__Spot')),
    Password__Spot: dynamic(() => import('../output/Password__Spot')),
    Payment__Spot: dynamic(() => import('../output/Payment__Spot')),
    Roaming__Spot: dynamic(() => import('../output/Roaming__Spot')),
    SecondLifeDevice__Spot: dynamic(() => import('../output/SecondLifeDevice__Spot')),
    Share__Spot: dynamic(() => import('../output/Share__Spot')),
    Sim__Spot: dynamic(() => import('../output/Sim__Spot')),
    Speed5G__Spot: dynamic(() => import('../output/Speed5G__Spot')),
    Success__Spot: dynamic(() => import('../output/Success__Spot')),
    Support__Spot: dynamic(() => import('../output/Support__Spot')),
    Tick__Spot: dynamic(() => import('../output/Tick__Spot')),
    Time__Spot: dynamic(() => import('../output/Time__Spot')),
    TopUp__Spot: dynamic(() => import('../output/TopUp__Spot')),
    Unlimited__Spot: dynamic(() => import('../output/Unlimited__Spot')),
    VoiceMail__Spot: dynamic(() => import('../output/VoiceMail__Spot')),
    Warning__Spot: dynamic(() => import('../output/Warning__Spot')),
    Warranty__Spot: dynamic(() => import('../output/Warranty__Spot')),
    Wifi__Spot: dynamic(() => import('../output/Wifi__Spot')),
    StarRating1: dynamic(() => import('../output/StarRating1')),
    StarRating2: dynamic(() => import('../output/StarRating2')),
    StarRating3: dynamic(() => import('../output/StarRating3')),
    StarRating4: dynamic(() => import('../output/StarRating4')),
    StarRating5: dynamic(() => import('../output/StarRating5')),
    Star: dynamic(() => import('../output/Star')),
    Success: dynamic(() => import('../output/Success')),
    Support: dynamic(() => import('../output/Support')),
    Tablet: dynamic(() => import('../output/Tablet')),
    TickCircle: dynamic(() => import('../output/TickCircle')),
    Tick: dynamic(() => import('../output/Tick')),
    Time: dynamic(() => import('../output/Time')),
    Twitter: dynamic(() => import('../output/Twitter')),
    Unlimited: dynamic(() => import('../output/Unlimited')),
    View: dynamic(() => import('../output/View')),
    Warning: dynamic(() => import('../output/Warning')),
    Wifi: dynamic(() => import('../output/Wifi')),
    Youtube: dynamic(() => import('../output/Youtube'))
};
export const URI_ICONS = {
    ChevronDown: "data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3e%3cpath fill='%23000' d='M20.002 9.414 18.588 8 12 14.587 5.414 8 4 9.414l7.997 7.997.005-.005.004.004 7.996-7.996Z'/%3e%3c/svg%3e"
};
