import {
  IContentfulSettings,
  ICatalogOffer,
  IOfferCampaign,
  IOfferContent,
  OFFER_CHANNEL,
  IMoleculeAlert,
  isEntry,
  IElementOfferAlert
} from '@belong/types';
import { getConfig } from '@belong/configs/next/config';
import { Cookies } from 'react-cookie';
import { FEATURES, FeaturesManager } from '@belong/feature-toggles';
import {
  getContentfulSettings,
  isErrorResponse,
  getEntries,
  getContentByGQL,
  isGQLErrorResponse,
  FElementOfferAlert,
  FMoleculeAlert
} from '@belong/contentful';
import { OFFER_TYPE } from '@belong/types/api/offers';
import { logger } from '@belong/logging';
import { FCatalogOffer, FOfferCampaign, FOfferContent, IProductData, ISiteOfferContent } from './factories';
import productQuery, { IProductQueryData } from './queries/products.gql';
import { getTemplateList } from './templates/config';
import { IOfferTemplate } from './OfferContentManager';

export async function loadSiteOfferContent(correlationId: string, cookieString?: string): Promise<ISiteOfferContent> {
  const isOffersEnabled = FeaturesManager.isFeatureOn(FEATURES.OFFER_MANAGEMENT_V2);
  const contentfulSettings: IContentfulSettings = getContentfulSettings(
    new Cookies(cookieString),
    getConfig().publicRuntimeConfig.contentful
  );

  // If toggle is OFF, offerContentManager will be empty
  let results;

  if (isOffersEnabled) {
    results = await Promise.all([
      loadActiveOffers(correlationId, contentfulSettings),
      loadActiveCampaigns(correlationId, contentfulSettings),
      loadRewardTemplates(),
      loadProductData()
    ]);
  } else {
    results = [
      [] as IOfferContent[],
      [] as IOfferCampaign[],
      {} as Record<string, IElementOfferAlert>,
      { products: {}, offerNames: {} } as IProductData
    ];
  }

  const [offers, campaigns, templates, { products, offerNames }] = results;
  return { offers, campaigns, templates, products, offerNames };
}

async function loadActiveOffers(
  correlationId: string,
  contentfulSettings: IContentfulSettings
): Promise<ICatalogOffer[]> {
  const response = await getEntries(
    {
      // eslint-disable-next-line camelcase
      content_type: 'catalogOffer',
      'fields.isActive': true,
      'fields.isATL': true,
      'fields.type': OFFER_TYPE.CHANNEL,
      'fields.eligibleChannels[in]': OFFER_CHANNEL.DIGITAL,
      include: 10
    },
    contentfulSettings,
    correlationId
  );

  if (isErrorResponse(response)) {
    logger.error('loadATLOffers - Failed to load offer content');
    return [];
  }
  return response.map(FCatalogOffer);
}

async function loadActiveCampaigns(
  correlationId: string,
  contentfulSettings: IContentfulSettings
): Promise<IOfferCampaign[]> {
  const response = await getEntries(
    {
      // eslint-disable-next-line camelcase
      content_type: 'offerCampaign',
      'fields.isActive': true,
      include: 10
    },
    contentfulSettings,
    correlationId
  );

  if (isErrorResponse(response)) {
    logger.error('loadOfferCampaigns - Failed to load offer campaigns', response);
    return [];
  }
  return response.map(FOfferCampaign);
}

async function loadProductData(): Promise<IProductData> {
  const response = await getContentByGQL<IProductQueryData>(productQuery);

  if (isGQLErrorResponse(response)) {
    logger.error('OfferContentManager - Failed to load product data', response.errors);
    return { products: {}, offerNames: {} };
  }

  const products = (response.data?.catalogProductCollection?.items || []).reduce(
    (acc, product) => ({ ...acc, [product.productCode]: product.priceValue }),
    {} as Record<string, number>
  );

  const offerNames = (response.data?.catalogOfferCollection?.items || []).reduce(
    (acc, offer) => ({ ...acc, [offer.id]: offer.name }),
    {} as Record<string, string>
  );

  return { products, offerNames };
}

// Load content for a single, specific offer
export async function loadOfferContent(offerId: string): Promise<IOfferContent | undefined> {
  const response = await getEntries(
    {
      // eslint-disable-next-line camelcase
      content_type: 'offerContent',
      'fields.offer.sys.contentType.sys.id': 'catalogOffer',
      'fields.offer.fields.id': offerId,
      include: 10,
      limit: 1
    },
    getContentfulSettings()
  );

  if (isErrorResponse(response)) {
    logger.error('loadOfferContent - Failed to load content for offer', offerId);
    return;
  }

  return FOfferContent(response[0]);
}

// Load product details for one or more offers
export async function loadOfferProducts(offerIds: string[]): Promise<Record<string, ICatalogOffer>> {
  if (!offerIds.length) {
    return {};
  }

  const response = await getEntries(
    {
      // eslint-disable-next-line camelcase
      content_type: 'catalogOffer',
      'fields.id[in]': offerIds.join(',')
    },
    getContentfulSettings()
  );

  if (isErrorResponse(response)) {
    logger.error('loadOfferProducts - Failed to load offer details', offerIds);
    return {};
  }

  const offers = response.map(FCatalogOffer);
  return offers.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});
}

// Retrieve the two different output types from Contentful
export async function loadRewardTemplates(): Promise<Record<string, IElementOfferAlert | IMoleculeAlert>> {
  const templateListJoined = getTemplateList().join(',');
  logger.debug('Loading offer templates:', templateListJoined);

  const response = await Promise.all([
    getEntries(
      {
        content_type: 'elementOfferAlert',
        'fields.code[in]': templateListJoined
      },
      getContentfulSettings()
    ),
    getEntries(
      {
        content_type: 'moleculeAlert',
        'fields.code[in]': templateListJoined
      },
      getContentfulSettings()
    )
  ]);

  if (isErrorResponse(response[0]) || isErrorResponse(response[1])) {
    logger.error('loadRewardTemplates - Failed to load offer templates/alerts', response);
    return {};
  }

  return response.flat().reduce(
    (acc, item) => {
      if (isEntry(item)) {
        let template: IOfferTemplate;
        if (item.sys.contentType.sys.id === 'moleculeAlert') {
          template = FMoleculeAlert(item);
        } else {
          template = FElementOfferAlert(item);
        }
        acc[template.code] = template;
      }
      return acc;
    },
    {} as Record<string, IOfferTemplate>
  );
}
