import { IErrorResponse, IProductCode, NBN_TECH_TYPE } from '..';

export enum SERVICE_QUALIFICATION_RESULTS {
  NO_ADDRESS_FOUND = 'NO_ADDRESS_FOUND', // no address found
  MULTIPLE_ADDRESSES_FOUND = 'MULTIPLE_ADDRESSES_FOUND', // found a group of potential matches
  NO_LOCATION = 'NO_LOCATION', // A single address was found but there is no nbn service id associated.
  SQ = 'SQ' // successfully found the address
}

export enum NDC_CHARGE {
  YES = 'Yes',
  NO = 'No'
}

export enum STACK_INFO {
  CLASSIC = 'Classic',
  NEW_STACK = 'New Stack'
}

/** For more information on service classes and address line status, please refer to https://belongranda.atlassian.net/wiki/spaces/BEL/pages/2184446967/Service+Classes  */
export enum SERVICEABILITY_STATUS {
  /** No Service Class or No tech type */
  SERVICE_NOT_AVAILABLE = 'SERVICE_NOT_AVAILABLE',

  /** Service Class 4, 5 or 6: serviceable by fixed wireless NBN */
  NOT_SERVICEABLE_BY_BELONG = 'NOT_SERVICEABLE_BY_BELONG',

  /** Service Class 7, 8 or 9: planned to be serviceable by satellite */
  NOT_SERVICEABLE_BY_TELSTRA = 'NOT_SERVICEABLE_BY_TELSTRA',

  /** Service Class 0, 10, 20 or 30: addresses of these service classes are not yet ready for connection,
   * however the techTypes for the addresses have been determined
   */
  NOT_READY_FOR_SERVICE = 'NOT_READY_FOR_SERVICE',

  SERVICEABLE = 'SERVICEABLE',

  /** Cannot determine serviceability */
  SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG',

  MULTIPLE_ACTIVE_SERVICES_FOUND = 'MULTIPLE_ACTIVE_SERVICES_FOUND',

  /** TODO by API */
  ALREADY_IN_PROGRESS = 'ALREADY_IN_PROGRESS'
}

export enum ADDRESS_LINE_STATUS {
  NO_INPLACE = 'NO_INPLACE',
  ACTIVE_LINE = 'ACTIVE_LINE',
  INACTIVE = 'INACTIVE'
}

export type PRODUCT_TYPES = 'PLAN' | 'MODEM' | 'NDC';

interface IServiceQualificationError extends IErrorResponse {}

export interface IServiceQualificationAddress {
  addressId: string;
  csaId?: string;
  localityName: string; // suburb
  locationId?: string;
  postcode: string;
  propertyName?: string;
  state: string;
  streetAddress: string;
  streetName: string;
  streetNumber: string;
  streetType?: string;
  subAddress?: string;
}

export interface IProactiveMas {
  // averaged peak/bottom download speed
  downstreamUpperRate?: number;
  downstreamLowerRate?: number;
  // averaged peak/bottom upload speed
  upstreamUpperRate?: number;
  upstreamLowerRate?: number;

  // nbn assured download/upload speed
  // numbers are often conservative
  // for proactive mas we only check
  // the presence
  downstreamCurrentAssuredRate?: number;
  upstreamCurrentAssuredRate?: number;
}

export interface IServiceQualification {
  status: SERVICEABILITY_STATUS;
  appointmentRequired: boolean;
  nbnTechType?: NBN_TECH_TYPE;
  nbnServiceClass?: string;
  newDevelopmentsCharge?: NDC_CHARGE;
  sqLineStatus?: ADDRESS_LINE_STATUS;
  serviceabilityDate?: string;
  // Maximum attainable speed readings,
  // to determine if plans should be available to the given service address
  mas?: IProactiveMas;
}

export interface INbnProduct extends IProductCode {
  productType: PRODUCT_TYPES;
}

export interface INbnPlan extends INbnProduct {
  childProducts?: INbnProduct[];
}

export interface INbnPromo {
  utmCampaign?: string;
  utmSource?: string;
  utmMedium?: string;
  promo?: string;
  externalId?: string;
  productId?: string;
}

export interface IContentLookup {
  productName?: string;
  campaignCode?: string;
  promoCode?: string;
  stackInfo?: STACK_INFO;
}

export interface IServiceQualificationDetails {
  address?: IServiceQualificationAddress;
  serviceQualification?: IServiceQualification;
  products?: Array<INbnPlan>;
}

export interface IServiceQualificationResponse {
  result: SERVICE_QUALIFICATION_RESULTS;
  data: Array<IServiceQualificationDetails>;
  error?: IServiceQualificationError;
  jwt?: string;
}

// Calling the location/:locationId API doesn't return the address information in the data
export interface IServiceQualificationLocationDetails extends Omit<IServiceQualificationDetails, 'address'> {}
export interface IServiceQualificationLocationResponse {
  result: SERVICE_QUALIFICATION_RESULTS;
  data: Array<IServiceQualificationLocationDetails>;
  error?: IServiceQualificationError;
  jwt?: string;
}
