import React from 'react';

import { ALERT_POSITION, Entry } from '@belong/types';
import { FImage, FVideo, FInfoCallout, FLink, FLinkListItem } from '@belong/contentful';

import { Button } from '../../../../actions/Button';
import { Alert } from '../../../../feedback';
import BaseRichText from '../../BaseRichText/BaseRichText';
import { Column, Columns } from '../../../../ui';
import { LinkList } from '../../../../lists';
import CountryList from '../../../../contentBlocks/CountryList/CountryList';
import MediaCaptionBlock from '../../../../contentBlocks/MediaCaptionBlock';
import { InfoCallout } from '../../../../contentBlocks/InfoCallout';
import { Icon } from '../../../../Icon';

export const elementLink = (entry: Entry<any>): JSX.Element => <Button {...FLink(entry)} />;

export const elementSystemIcon = (entry: Entry<any>): JSX.Element => {
  const { fields } = entry;
  return <Icon name={fields.icon} size={fields.size?.toLowerCase()} role="presentation" className="inline-icon" />;
};

export const moleculeAlert = (entry: Entry<any>): JSX.Element => (
  <Alert position={ALERT_POSITION.BLOCK} type={entry.fields.level}>
    <BaseRichText>{entry.fields.message}</BaseRichText>
  </Alert>
);

export const moleculeLinkList = (entry: Entry<any>): JSX.Element => {
  const items = entry.fields.items.map(FLinkListItem);
  return (
    <Columns>
      <Column width={{ md: '5/6', lg: '2/3', xl: '1/2' }}>
        <LinkList items={items} />
      </Column>
    </Columns>
  );
};

export const countryList = (entry: Entry<any>): JSX.Element => {
  const hasColumns = { xs: 2, sm: 3, md: 5 };
  const { countries } = entry.fields;
  return <CountryList countries={countries} hasColumns={hasColumns} />;
};

export const moleculeMediaCaptionBlock = (entry: Entry<any>): JSX.Element => {
  const { image, imageAspectRatio, video, ...fields } = entry.fields;
  const props = {
    ...fields,
    image: image && FImage(image),
    aspectRatio: imageAspectRatio,
    video: video && FVideo(video)
  };
  return <MediaCaptionBlock {...props} />;
};

export const moleculeInformationCalloutRow = (entry: Entry<any>): JSX.Element => {
  const callout = FInfoCallout(entry);
  return <InfoCallout {...callout} />;
};
