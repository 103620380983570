export type AddressPID = string;

export enum NBNServiceClass {
  ZERO = 'NBN_SERVICE_CLASS_ZERO',
  ONE = 'NBN_SERVICE_CLASS_ONE',
  TWO = 'NBN_SERVICE_CLASS_TWO',
  THREE = 'NBN_SERVICE_CLASS_THREE',
  FOUR = 'NBN_SERVICE_CLASS_FOUR',
  FIVE = 'NBN_SERVICE_CLASS_FIVE',
  SIX = 'NBN_SERVICE_CLASS_SIX',
  SEVEN = 'NBN_SERVICE_CLASS_SEVEN',
  EIGHT = 'NBN_SERVICE_CLASS_EIGHT',
  NINE = 'NBN_SERVICE_CLASS_NINE',
  TEN = 'NBN_SERVICE_CLASS_TEN',
  ELEVEN = 'NBN_SERVICE_CLASS_ELEVEN',
  TWELVE = 'NBN_SERVICE_CLASS_TWELVE',
  THIRTEEN = 'NBN_SERVICE_CLASS_THIRTEEN',
  FOURTEEN = 'NBN_SERVICE_CLASS_FOURTEEN',
  FIFTEEN = 'NBN_SERVICE_CLASS_FIFTEEN',
  SIXTEEN = 'NBN_SERVICE_CLASS_SIXTEEN',
  SEVENTEEN = 'NBN_SERVICE_CLASS_SEVENTEEN',
  EIGHTTEEN = 'NBN_SERVICE_CLASS_EIGHTTEEN',
  NINETEEN = 'NBN_SERVICE_CLASS_NINETEEN',
  TWENTY = 'NBN_SERVICE_CLASS_TWENTY',
  TWENTY_ONE = 'NBN_SERVICE_CLASS_TWENTY_ONE',
  TWENTY_TWO = 'NBN_SERVICE_CLASS_TWENTY_TWO',
  TWENTY_THREE = 'NBN_SERVICE_CLASS_TWENTY_THREE',
  TWENTY_FOUR = 'NBN_SERVICE_CLASS_TWENTY_FOUR',
  TWENTY_FIVE = 'NBN_SERVICE_CLASS_TWENTY_FIVE',
  TWENTY_SIX = 'NBN_SERVICE_CLASS_TWENTY_SIX',
  TWENTY_SEVEN = 'NBN_SERVICE_CLASS_TWENTY_SEVEN',
  TWENTY_EIGHT = 'NBN_SERVICE_CLASS_TWENTY_EIGHT',
  TWENTY_NINE = 'NBN_SERVICE_CLASS_TWENTY_NINE',
  THIRTY = 'NBN_SERVICE_CLASS_THIRTY',
  THIRTY_ONE = 'NBN_SERVICE_CLASS_THIRTY_ONE',
  THIRTY_TWO = 'NBN_SERVICE_CLASS_THIRTY_TWO',
  THIRTY_THREE = 'NBN_SERVICE_CLASS_THIRTY_THREE',
  THIRTY_FOUR = 'NBN_SERVICE_CLASS_THIRTY_FOUR'
}

export enum API_ENDPOINTS {
  PARTIAL_ADDRESS_SEARCH = '/address/v1/partialaddresses',
  /**
   * @see https://www.edq.com/documentation/apis-r/address-validate/experian-address-validation/#/AddressFormat
   */
  GET_ADDRESS_V2_FRAGMENTS = '/address/v2/location',
  ADDRESS_ENTITY = '/adsq-orchestrator/v1/sq/address',
  PROMO_CODE_VALIDATE = '/bin/belong/join/promo/validate'
}
