import React from 'react';
import { logger } from '@belong/logging/logger';
import { BREAKPOINTS } from '@belong/themes';
import { IMAGE_ASPECT_RATIO, IMediaCaptionBlock } from '@belong/types';
import { getResponsiveSrc } from '@belong/utils';
import { VideoContainer, VideoEmbed, PictureContainer } from '../../media';

import { Container, AspectRatioContainer, Caption } from './styles';
import { multipleSources } from './utils';

interface IMediaCaptionBlockProps extends IMediaCaptionBlock {
  useOriginalImageSize?: boolean;
}

export const TEST_ID = {
  container: 'media-caption-block',
  mediaElement: 'media-caption-block--media',
  caption: 'media-caption-block--caption'
};

const MediaElement: React.FC<IMediaCaptionBlockProps> = ({
  image,
  video,
  videoId,
  aspectRatio = IMAGE_ASPECT_RATIO.letterbox,
  useOriginalImageSize
}) => {
  if (image) {
    const src = getResponsiveSrc(image, {
      xs: { w: BREAKPOINTS.md },
      md: { w: BREAKPOINTS.lg }
    });

    const picture = <PictureContainer {...image} src={src} />;

    if (useOriginalImageSize) {
      return picture;
    }

    return (
      <AspectRatioContainer data-testid={TEST_ID.mediaElement} ratio={aspectRatio}>
        {picture}
      </AspectRatioContainer>
    );
  }

  if (videoId) {
    return <VideoEmbed data-testid={TEST_ID.mediaElement} videoId={videoId} />;
  }

  if (video) {
    return <VideoContainer data-testid={TEST_ID.mediaElement} {...video} />;
  }

  return null;
};

MediaElement.displayName = 'MediaElement';

/**
 * Embeddable component to display either YouTube Video or an Image with an optional caption
 */
export const MediaCaptionBlock: React.FC<IMediaCaptionBlockProps> = props => {
  const { image, video, videoId, caption, useOriginalImageSize } = props;
  const hasMedia = !!image || !!videoId || !!video;
  const hasMultipleSources = multipleSources(image, videoId, video);

  if (!hasMedia || hasMultipleSources) {
    logger.error('Please check media sources - can only render one source');
    return null;
  }

  const mediaElement = <MediaElement data-testid={TEST_ID.mediaElement} {...props} />;

  return (
    <Container
      data-testid={TEST_ID.container}
      className="media-caption-block"
      useOriginalImageSize={useOriginalImageSize}
    >
      {mediaElement}
      {caption && <Caption data-testid={TEST_ID.caption}>{caption}</Caption>}
    </Container>
  );
};

MediaCaptionBlock.displayName = 'MediaCaptionBlock';

export default MediaCaptionBlock;
