// This file is generated by build.js
// DO NOT EDIT IT MANUALLY

export enum ICON_NAMES {
  Activation = 'Activation',
  AddCircle = 'AddCircle',
  AddUser = 'AddUser',
  Add = 'Add',
  ArrowDown = 'ArrowDown',
  ArrowUp = 'ArrowUp',
  Avatar = 'Avatar',
  Award = 'Award',
  BoltActive = 'BoltActive',
  BoltInactive = 'BoltInactive',
  Mastercard__Brand = 'Mastercard__Brand',
  PayPal__Brand = 'PayPal__Brand',
  PayPalVertical__Brand = 'PayPalVertical__Brand',
  Visa__Brand = 'Visa__Brand',
  Calendar = 'Calendar',
  Chat = 'Chat',
  ChevronDown = 'ChevronDown',
  ChevronLeft = 'ChevronLeft',
  ChevronRight = 'ChevronRight',
  ChevronUp = 'ChevronUp',
  CloseCircle = 'CloseCircle',
  Close = 'Close',
  ComponentError = 'ComponentError',
  Copy = 'Copy',
  CreditCard = 'CreditCard',
  Danger = 'Danger',
  Data = 'Data',
  Download = 'Download',
  Email = 'Email',
  Calendar__Emoji = 'Calendar__Emoji',
  CheckMark__Emoji = 'CheckMark__Emoji',
  Diamond__Emoji = 'Diamond__Emoji',
  Dolphin__Emoji = 'Dolphin__Emoji',
  FaceCowboyHat__Emoji = 'FaceCowboyHat__Emoji',
  FaceJoyTears__Emoji = 'FaceJoyTears__Emoji',
  FaceSmilingSunglasses__Emoji = 'FaceSmilingSunglasses__Emoji',
  Fire__Emoji = 'Fire__Emoji',
  Ghost__Emoji = 'Ghost__Emoji',
  GlobeAmerica__Emoji = 'GlobeAmerica__Emoji',
  GlobeNetwork__Emoji = 'GlobeNetwork__Emoji',
  Globe__Emoji = 'Globe__Emoji',
  GrimacingFace__Emoji = 'GrimacingFace__Emoji',
  HammerPick__Emoji = 'HammerPick__Emoji',
  Indonesia__Emoji = 'Indonesia__Emoji',
  LightningBolt__Emoji = 'LightningBolt__Emoji',
  NetworkBars__Emoji = 'NetworkBars__Emoji',
  NewZealand__Emoji = 'NewZealand__Emoji',
  Phone__Emoji = 'Phone__Emoji',
  Plus__Emoji = 'Plus__Emoji',
  Shrimp__Emoji = 'Shrimp__Emoji',
  Singapore__Emoji = 'Singapore__Emoji',
  Star__Emoji = 'Star__Emoji',
  Telephone__Emoji = 'Telephone__Emoji',
  UnitedKingdom__Emoji = 'UnitedKingdom__Emoji',
  USA__Emoji = 'USA__Emoji',
  EyeHide = 'EyeHide',
  Facebook = 'Facebook',
  Hotspot = 'Hotspot',
  Info = 'Info',
  Instagram = 'Instagram',
  International = 'International',
  Internet = 'Internet',
  Invoices = 'Invoices',
  Laptop = 'Laptop',
  LinkedIn = 'LinkedIn',
  LogIn = 'LogIn',
  LogOut = 'LogOut',
  Map = 'Map',
  Media = 'Media',
  Menu = 'Menu',
  Messaging = 'Messaging',
  Minimize = 'Minimize',
  Mobile = 'Mobile',
  More = 'More',
  Moving = 'Moving',
  Music = 'Music',
  MyServices = 'MyServices',
  NewWindow = 'NewWindow',
  PaperClip = 'PaperClip',
  PaperPlane = 'PaperPlane',
  Password = 'Password',
  Payment = 'Payment',
  Phone = 'Phone',
  Plus = 'Plus',
  Profile = 'Profile',
  Recycling = 'Recycling',
  Remove = 'Remove',
  Roaming = 'Roaming',
  Search = 'Search',
  Settings = 'Settings',
  Sim = 'Sim',
  SpeedHigh = 'SpeedHigh',
  SpeedLow = 'SpeedLow',
  SpeedMedium = 'SpeedMedium',
  Account__Spot = 'Account__Spot',
  ActivateSim__Spot = 'ActivateSim__Spot',
  AddMobile__Spot = 'AddMobile__Spot',
  AddSim__Spot = 'AddSim__Spot',
  AwardWinner__Spot = 'AwardWinner__Spot',
  Calendar__Spot = 'Calendar__Spot',
  Calls__Spot = 'Calls__Spot',
  CarbonNeutral__Spot = 'CarbonNeutral__Spot',
  Chat__Spot = 'Chat__Spot',
  CreditCard__Spot = 'CreditCard__Spot',
  Cross__Spot = 'Cross__Spot',
  CurrentPlan__Spot = 'CurrentPlan__Spot',
  DataGift__Spot = 'DataGift__Spot',
  Email__Spot = 'Email__Spot',
  Error__Spot = 'Error__Spot',
  International__Spot = 'International__Spot',
  Internet__Spot = 'Internet__Spot',
  Invoice__Spot = 'Invoice__Spot',
  Map__Spot = 'Map__Spot',
  Media__Spot = 'Media__Spot',
  Mobile__Spot = 'Mobile__Spot',
  MobileServicePrivate__Spot = 'MobileServicePrivate__Spot',
  MobileServicePublic__Spot = 'MobileServicePublic__Spot',
  Music__Spot = 'Music__Spot',
  Nbn__Spot = 'Nbn__Spot',
  NetHealth__Spot = 'NetHealth__Spot',
  Network__Spot = 'Network__Spot',
  NewPlan__Spot = 'NewPlan__Spot',
  Number1__Spot = 'Number1__Spot',
  Number2__Spot = 'Number2__Spot',
  Number3__Spot = 'Number3__Spot',
  Number4__Spot = 'Number4__Spot',
  Number5__Spot = 'Number5__Spot',
  Number6__Spot = 'Number6__Spot',
  Password__Spot = 'Password__Spot',
  Payment__Spot = 'Payment__Spot',
  Roaming__Spot = 'Roaming__Spot',
  SecondLifeDevice__Spot = 'SecondLifeDevice__Spot',
  Share__Spot = 'Share__Spot',
  Sim__Spot = 'Sim__Spot',
  Speed5G__Spot = 'Speed5G__Spot',
  Success__Spot = 'Success__Spot',
  Support__Spot = 'Support__Spot',
  Tick__Spot = 'Tick__Spot',
  Time__Spot = 'Time__Spot',
  TopUp__Spot = 'TopUp__Spot',
  Unlimited__Spot = 'Unlimited__Spot',
  VoiceMail__Spot = 'VoiceMail__Spot',
  Warning__Spot = 'Warning__Spot',
  Warranty__Spot = 'Warranty__Spot',
  Wifi__Spot = 'Wifi__Spot',
  StarRating1 = 'StarRating1',
  StarRating2 = 'StarRating2',
  StarRating3 = 'StarRating3',
  StarRating4 = 'StarRating4',
  StarRating5 = 'StarRating5',
  Star = 'Star',
  Success = 'Success',
  Support = 'Support',
  Tablet = 'Tablet',
  TickCircle = 'TickCircle',
  Tick = 'Tick',
  Time = 'Time',
  Twitter = 'Twitter',
  Unlimited = 'Unlimited',
  View = 'View',
  Warning = 'Warning',
  Wifi = 'Wifi',
  Youtube = 'Youtube'
}
