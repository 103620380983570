import dayjs, { ConfigType, Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import dayjsTz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(dayjsTz);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);

export const DATE_FORMAT = {
  DATE_SHORT_MONTH: 'DD MMM',
  DAY: 'dddd',
  DAY_SHORT: 'ddd',
  DAY_MONTH: 'D MMMM',
  DATE_ORDINAL: 'Do',
  DAY_MONTH_YEAR: 'DD MMMM YYYY',
  DEFAULT: 'DD MMMM YYYY',
  DEFAULT_SHORT_MONTH: 'DD MMM YYYY',
  INPUT: 'DD/MM/YYYY',
  INPUT_MONTH_YEAR: 'MM/YYYY',
  ISO: 'YYYY-MM-DD',
  ISO_8601: 'YYYY-MM-DDThh:mm:ss.sTZD',
  SHORT_MONTH_YEAR: 'MMM YYYY',
  TIME: 'h:mma',
  HOUR_ONLY_TIME: 'ha',
  YEAR: 'YYYY',
  YMD: 'YYYY/MM/DD',
  WEEKDAY_DAY_MONTH: 'dddd DD MMMM',
  WEEKDAY_DAY_MONTH_YEAR: 'dddd D MMMM YYYY'
} as const;

export type IDateInputFormat = typeof DATE_FORMAT.INPUT | typeof DATE_FORMAT.INPUT_MONTH_YEAR;

export interface IInputFormatterArgs {
  format?: IDateInputFormat;
  value: string;
  prevValue: string;
}

/**
 *
 * @param date Date string to be formatted
 * @param outputFormat Desired output format
 * @param inputFormat Known input format. If undefined, dayjs will try to guess the format
 * Check https://day.js.org/docs/en/parse/string-format for more info
 * @returns converted date string or empty string if input is falsy
 */
export const formatDate = (
  date: string | number | Dayjs | Date | null | undefined,
  outputFormat: string = DATE_FORMAT.DEFAULT,
  inputFormat?: string // undefined to let dayjs decide
): string => (date ? dayjs(date, inputFormat).format(outputFormat) : '');

export const getAge = (date: ConfigType): number => {
  const inputDate = getDate(date).year();
  const currentYear = new Date().getFullYear();

  return currentYear - inputDate;
};

export const formatSeconds = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const extraSeconds = seconds % 60;

  return `${minutes}:${extraSeconds < 10 ? `0${extraSeconds}` : extraSeconds}`;
};

export type TIMEZONES = 'Australia/Melbourne';
export const getDate = (date: ConfigType, format: string = DATE_FORMAT.ISO, timezone?: TIMEZONES): Dayjs => {
  let result: Dayjs;

  if (typeof date === 'string') {
    // format only applies to string input
    // and when we set strict to true, and date is not a string, it returns invalid date
    result = dayjs(date, format, true);
  } else {
    result = dayjs(date);
  }

  if (timezone) {
    result = result.tz(timezone);
  }

  return result;
};

export const formatValueForDateInput = ({
  value,
  prevValue,
  format = DATE_FORMAT.INPUT
}: IInputFormatterArgs): string => {
  const MAX_SECTIONS = format === DATE_FORMAT.INPUT_MONTH_YEAR ? 2 : 3;

  let nextInputValue = value;
  if (/[^0-9/]/.test(nextInputValue)) {
    return prevValue;
  }

  // If they press backspace when the input ends with a '/', it should
  // "backspace" the number; and prevent subsequent logic from
  // reinserting the '/'
  if (prevValue === `${nextInputValue}/` && prevValue[prevValue.length - 1] === '/') {
    nextInputValue = nextInputValue.substr(0, nextInputValue.length - 1);

    return nextInputValue;
  }

  if (
    // if they're not backspacing (i.e. is next value prevValue without the last char)
    nextInputValue !== prevValue.slice(0, prevValue.length - 1) &&
    // and if there's already a slash
    nextInputValue[nextInputValue.length - 1] === '/'
  ) {
    // set the input value to what it was before they hit slash
    return prevValue;
  }

  const nextInputValueSections = nextInputValue.split('/');
  const numberOfSections = nextInputValueSections.length;

  // Input should not have more than MAX_SECTIONS slash delimited sections in the string
  if (numberOfSections > MAX_SECTIONS) {
    return nextInputValue;
  }

  // In case they manually add in a / for single digit days/months
  if (nextInputValue === `${prevValue}/`) {
    const inputStart = nextInputValue.slice();
    const inputNewValue = nextInputValue.slice(nextInputValue.length - 1, 1);
    nextInputValue = `${inputStart}0${inputNewValue}/`;
    return nextInputValue;
  }

  // Get the YEAR section of the input. If the length input would make it longer than 4 (i.e. YYYY...Y)
  // then nullify the keystroke
  const YEAR_SECTION = MAX_SECTIONS - 1;
  if (numberOfSections > YEAR_SECTION && nextInputValueSections[YEAR_SECTION].length > 4) {
    return prevValue;
  }

  // Automatic / insertion when two digit day and month entered
  if (numberOfSections < MAX_SECTIONS && nextInputValueSections[numberOfSections - 1].length === 2) {
    nextInputValueSections[numberOfSections - 1] = `${nextInputValueSections[numberOfSections - 1]}/`;
  }

  nextInputValue = nextInputValueSections.join('/').replace('/ /', '/');

  return nextInputValue;
};

/**
 * Sorts an array of "items" by a configurable date property.
 * Supports all the date formats Day.js supports.
 *
 * @param {T[]} items - an array of items to sort
 * @param {string} dateAttribute - the name of the item's date property to sort by
 * @param {Object} options - an option
 * @returns {T[]} the sorted array
 */
export const sortByDate = <T>(
  items: T[],
  dateAttribute: string,
  options: { isDescending?: boolean; missingAtEnd?: boolean } = { isDescending: true, missingAtEnd: true }
): T[] => {
  return [...items].sort((a, b): number => {
    // Sort items with missing date values to start/end of list
    if (!a[dateAttribute]) {
      return options.missingAtEnd ? 1 : -1;
    }
    if (!b[dateAttribute]) {
      return options.missingAtEnd ? -1 : 1;
    }

    // Sort items by date, order according to isDescending value
    const checkFn = options.isDescending ? 'isBefore' : 'isAfter';
    return dayjs(a[dateAttribute])[checkFn](dayjs(b[dateAttribute])) ? 1 : -1;
  });
};

export const daysInBetween = (startDate: Date, targetDate: Date): number => {
  const MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000;
  return Math.ceil((targetDate.valueOf() - startDate.valueOf()) / MILLISECONDS_PER_DAY);
};

/**
 *
 * @description Business logic says: the FIRST
 * DAY of every month is the due date for all internet services
 *
 * This function does not specify any timezone. 'startDate' is valued
 * according to environment's locale. It also means we are not able to assert how many days away from 1st of coming month,
 * the result is subject to timezone. But the main calculation 'daysInBetween' is splitted out, and testable.
 *
 * @todo If new use cases surface, refactor this function to also accept end Dates
 */
export const daysToNextInternetBill = (startDate = new Date()): number => {
  // if date is 1st day of the month
  if (startDate.getDate() === 1) {
    return 0;
  }
  const firstOfNextMonth = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);
  return daysInBetween(startDate, firstOfNextMonth);
};

export const endOfMonth = (dateFormat = 'DD/MM/YYYY'): string => {
  return dayjs().endOf('month').format(dateFormat);
};

export const pastDate = (dateFormat = 'DD/MM/YYYY'): string => {
  return dayjs().subtract(1, 'month').format(dateFormat);
};

export const todayDate = (dateFormat = 'DD/MM/YYYY'): string => {
  return dayjs().format(dateFormat);
};

export const nextMonthDate = (dateFormat = 'DD/MM/YYYY'): string => {
  return dayjs().add(1, 'month').format(dateFormat);
};

export const futureDateWithinDays = (withinDays: number, dateFormat = 'DD/MM/YYYY'): string => {
  return dayjs().add(withinDays, 'day').format(dateFormat);
};

export const nearFutureDate = (dateFormat = 'DD/MM/YYYY'): string => {
  return futureDateWithinDays(10, dateFormat);
};

export const removeOffset = (date: string): string => date.replace(/[+-]\d{2}:\d{2}$/, '');

export default dayjs;
