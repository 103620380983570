export enum KEYBOARD_KEYS {
  ARROW_UP = 'ArrowUp',
  ARROW_DOWN = 'ArrowDown',
  ENTER = 'Enter',
  ESCAPE = 'Escape',
  TAB = 'Tab',
  SPACE = ' '
}

export enum ORDER_STATUS {
  DRAFT = 'DRAFT',
  ORDER_NOT_FOUND = 'ORDER_NOT_FOUND',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED'
}

export enum PAYMENT_TYPES {
  CREDIT_CARD = 'CreditCard',
  PAYPAL = 'PayPalAccount'
}

export enum PORT_IN_TYPE {
  NEW = 'New',
  PORT_IN = 'Port-In'
}

export enum VERIFICATION_REQUEST_TYPE {
  NEW = 'New',
  PORT_IN = 'Port-In',
  DISCONNECTION = 'Disconnection',
  PAYMENT_METHOD_UPDATE = 'Payment Method Update',
  CONTACT_NUMBER_UPDATE = 'Contact Number Update'
}

export enum VERIFICATION_REQUEST_METHOD {
  SMS = 'SMS'
}

export const STREET_ADDRESS_MAX_LENGTH = 35;

export const REGEX = {
  AUS_MOBILE_PHONE: /^(?:\+?61|0)[4](?:[ -]?[0-9]){8}$/,
  ALPHANUMERIC_NO_SPACES: /^[a-zA-Z0-9]*$/,
  SIM: /^(\d{9}|\d{13})?$/,
  SIX_DIGITS: /^(\d{6})?$/,
  POSTCODE: /^(\d{4})?$/,
  STREET_ADDRESS: new RegExp(`^[a-zA-Z0-9 \\-,'"\\/\\\\\\.]{1,${STREET_ADDRESS_MAX_LENGTH}}$`),
  FIRST_NAME: /^(?=[a-zA-Z])[a-zA-Z\- ']{1,40}$/,
  LAST_NAME: /^(?=[a-zA-Z])[a-zA-Z\- ']{1,80}$/,
  SUB_ADDRESS_NUMBER: /^\d{1,}[A-Za-z]?([/-]\d{1,}[A-Za-z]?)?$/,
  STREET_NUMBER: /^\d{1,}[A-Za-z]?([-]\d{1,}[A-Za-z]?)?$/, // same as SUB_ADDRESS_NUMBER but without slash /
  LOCALITY: /^[a-zA-Z \-,'"]+$/,
  // Only allow numbers, and /
  // eslint-disable-next-line no-useless-escape
  DATE_OF_BIRTH_INPUTS: /[^0-9\/]/,

  // positive lookahead for (lowercase, uppercase, digit, owasp password special characters)
  // within at least 8 characters
  // eslint-disable-next-line no-useless-escape
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\\" !"#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~"]).{8,}$/,
  PO_BOX:
    /(\b(po|post office)\b[ |.]\b(box)\b)|(\b(pobox)\b)|(\b(parcel)\b[ |.]\b(locker|collect)\b)|\b(parcellocker)\b/i,
  EMAIL:
    /^(([^<>()[\]\\.,;:\s@"+]+(\.[^<>()[\]\\.,;:\s@"+]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
  ANYTHING_BUT_NUMBERS: /[^\d]/g
};

/**
 * States of the ApplyCode component
 */
export enum APPLY_CODE_STATES {
  DEFAULT = 'DEFAULT',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS'
}

export const CHARCODES = {
  ENTER: 13
};

export enum NUMBER_TYPE {
  PRE_PAID = 'PRE_PAID',
  POST_PAID = 'POST_PAID'
}

export enum CUSTOMER_STATUS {
  prospect = 'prospect',
  pending = 'pending',
  active = 'active'
}

export const LIB_INPUTS_DEFAULT_IDS = {
  ACCOUNT_NUMBER: 'accountNumber',
  DOB: 'dateOfBirth',
  DISCONNECTION_DATE: 'dateOfDisconnection',
  EMAIL: 'email',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  MOBILE: 'mobileNumber',
  PASSWORD: 'password',
  POSTCODE: 'postcode',
  VERIFICATION_CODE: 'verificationCode',
  SIM_NUMBER: 'simNumber'
} as const;

export const ARIA_CURRENT = 'page';
