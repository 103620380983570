import { Cookies } from 'react-cookie';
import fetch from 'isomorphic-unfetch';
import { logger } from '@belong/logging/logger';

import localToggles from './local.json';
import { FEATURES, CONSUMER_TAG, OVERRIDE_COOKIE } from './constants';

export type IFeatureToggles = Record<string, boolean>;

export interface IFeatureToggleSource {
  id: string;
  active: boolean;
  activeBlue?: boolean;
  description: string;
  tags: string[];
  createdDate: string;
}

const isValueOn = (value: string): boolean => ['on', 'true'].includes(value);

/**
 * Loads and parses the appropriate toggles for the current environment.
 * If running in a BLUE environment, grab the BLUE toggle state and fallback
 * to GREEN toggle state if not defined
 */
export const fetchToggles = async (url: string, isBlue = false): Promise<IFeatureToggles> => {
  const res = await fetch(url);
  const toggles: IFeatureToggleSource[] = await res.json();

  return (toggles || [])
    .filter(toggle => toggle.tags.includes(CONSUMER_TAG))
    .reduce(
      (acc, { id, active, activeBlue }) => ({
        ...acc,
        [id]: isBlue ? activeBlue ?? active : active
      }),
      {}
    );
};

class FeaturesManager {
  private values: IFeatureToggles = {};
  private isInitDone = false;
  /**
   * Only warn once per session
   * (always suppress the warning when running in Jest)
   */
  private hasWarnedAboutUsageBeforeSeeding = false;

  public init(correlationId: string, featuresMap: IFeatureToggles, cookie?: string): void {
    logger.debug(correlationId, 'FeaturesManager::init()');
    this.values = { ...featuresMap };

    if (process.env.NODE_ENV === 'development') {
      this.values = { ...featuresMap, ...localToggles };
    }

    this.applyCookieOverrides(correlationId, cookie);
    this.isInitDone = true;
  }

  public async load(url: string, cookie = '', isBlue = false, correlationId = ''): Promise<void> {
    logger.debug(correlationId, 'FeaturesManager::load()', { isBlue });

    if (!url) {
      logger.warn(correlationId, `no feature toggles url provided: ${url}`);
      this.init(correlationId, {}, cookie);
      return;
    }

    try {
      const toggles = await fetchToggles(url, isBlue);
      this.init(correlationId, toggles, cookie);
    } catch (e) {
      logger.error(correlationId, 'Unable to load external feature toggles', e.message);
      this.init(correlationId, {}, cookie);
    }
  }

  public isFeatureOn(feature: string): boolean {
    if (!this.isInitDone || !this.values) {
      if (!this.hasWarnedAboutUsageBeforeSeeding) {
        logger.error('FeaturesManager.isFeatureOn used before it was seeded with any feature values!');
        this.hasWarnedAboutUsageBeforeSeeding = true;
      }
      return false;
    }
    // ensure false value if toggle isn't defined
    return this.values[feature] ?? false;
  }

  public override(correlationId: string, feature: string, value: boolean): void {
    const style = 'color:darkred; font-weight:600; background-color:#8FEAEA; padding:2px 6px';
    // eslint-disable-next-line no-console
    console.info(`${correlationId} %c🚨 Override FeatureToggle: [${feature}: ${value}]`, style);
    this.values[feature] = value;
  }

  /**
   * Check for toggle-override cookie and apply new settings
   * Cookie param is only supplied for server rendering - defaults to loading cookies from window scope
   * Example cookie:
   *  Name: toggle-overrides, Value: 'planned-outage-notification=on'
   *  Note:
   *    override state can be 'true' or 'on' (case insensitive)
   *    any other values are considered as 'false'
   */
  public applyCookieOverrides(correlationId: string, cookie?: string): void {
    const parsedCookie = new Cookies(cookie);
    const toggleOverrides = parsedCookie.get(OVERRIDE_COOKIE) || '';

    const toggles = toggleOverrides.split(/\s*,\s*/).filter(Boolean);

    if (toggles && toggles.length > 0) {
      const featureNames = Object.values(FEATURES);
      toggles.forEach(override => {
        const [name, state] = override.split('=');
        if (name && featureNames.includes(name)) {
          this.override(correlationId, name, isValueOn(state.toLowerCase()));
        }
      });
    }
  }

  public export(): IFeatureToggles {
    return { ...this.values };
  }
}

const featuresManager = new FeaturesManager();
export default featuresManager;
