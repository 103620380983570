import type { IElementOfferAlert } from '@belong/types';
import { FMoleculeCallout } from '../molecules/moleculeCallout.factory';
import { FRichText } from '../richText.factory';

export const FElementOfferAlert = (data: any): IElementOfferAlert => {
  const { description, callout, code } = data.fields;
  return {
    id: data.sys.id,
    contentType: data.sys.contentType,
    description: description && FRichText(description),
    callout: FMoleculeCallout(callout),
    code
  };
};
